import type { IdeaState } from './state'

export default {
  ideas(state: IdeaState): Idea[] | undefined {
    return state.ideas
  },
  ideasDict(state: IdeaState): Record<IdeaId, Idea> | undefined {
    return state.ideasDict
  },
  ideasCount(state: IdeaState): number {
    return state.ideas ? state.ideas.length : 0
  },
  firstIdeaDate(state: IdeaState): string | undefined {
    return state.ideas?.length
      ? state.ideas[state.ideas.length - 1]?.created_dts.substring(0, 10)
      : undefined
  },
}
