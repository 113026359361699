import { createStore } from 'vuex'
import actions from './actions'

import dreamsActions from './dreams/actions'
import dreamsGetters from './dreams/getters'
import dreamsMutations from './dreams/mutations'
import dreamsState from './dreams/state'

import focusActions from './focus/actions'
import focusGetters from './focus/getters'
import focusMutations from './focus/mutations'
import focusState from './focus/state'

import gemsActions from './gems/actions'
import gemsGetters from './gems/getters'
import gemsMutations from './gems/mutations'
import gemsState from './gems/state'

import getters from './getters'
import ideasActions from './ideas/actions'
import ideasGetters from './ideas/getters'
import ideasMutations from './ideas/mutations'

import ideasState from './ideas/state'
import journalActions from './journal/actions'
import journalGetters from './journal/getters'
import journalMutations from './journal/mutations'

import journalState from './journal/state'
import mutations from './mutations'
import notesActions from './notes/actions'
import notesGetters from './notes/getters'

import notesMutations from './notes/mutations'
import notesState from './notes/state'
import peopleActions from './people/actions'
import peopleGetters from './people/getters'

import peopleMutations from './people/mutations'
import peopleState from './people/state'
import { createPersistedState } from './plugins'
import state from './state'

import tagsActions from './tags/actions'
import tagsGetters from './tags/getters'
import tagsMutations from './tags/mutations'
import tagsState from './tags/state'

import todosActions from './todos/actions'
import todosGetters from './todos/getters'
import todosMutations from './todos/mutations'
import todosState from './todos/state'

const vuexPersistedStatePlugin = createPersistedState({
  modules: [
    'dreams',
    'focus',
    'gems',
    'ideas',
    'journal',
    'notes',
    'people',
    'tags',
    'todos',
  ],
  paths: [
    // IMPORTANT:
    // Do not add 'auth' here, it will break the navigation guard...
    'profile',
    'profileSettings',
    'device',
    'deviceSettings',
    'sync',
    'ui',
    'version',
    // Modules
    'dreams.dreams',
    'focus.goals',
    'gems.gems',
    'ideas.ideas',
    'journal.entries',
    'journal.bullets',
    'notes.notes',
    'notes.noteLabels',
    'people.people',
    'people.personGroups',
    'tags.tags',
    'tags.tagGroups',
    'todos.todos',
  ],
  skipMutations: [
    'updateUIx',
  ],
  onRehydrated: (store) => {
    // Recompile object dicts.
    console.time('[vuex] recompile dicts')
    store.commit('populateActionsDict')
    store.commit('dreams/populateDreamsDict')
    store.commit('focus/populateGoalsDict')
    store.commit('gems/populateGemDict')
    store.commit('ideas/populateIdeaDict')
    store.commit('journal/populateEntriesDict')
    store.commit('journal/populateBulletsDict')
    store.commit('notes/populateNotesDict')
    store.commit('notes/populateNoteLabelsDict')
    store.commit('people/populatePeopleDict')
    store.commit('people/populatePersonGroupsDict')
    store.commit('tags/populateTagsDict')
    store.commit('tags/populateTagGroupsDict')
    store.commit('todos/populateTodosDict')
    console.timeEnd('[vuex] recompile dicts')
  },
})

// const vuexPersistPlugin = new VuexPersistence({
//   key: 'vuex-persist',
//   storage: {
//     getItem: async (key: string) => {
//       console.log('GET', key)
//       const raw: string | undefined = await localForage.getItem(key)
//       const data = raw ? JSON.parse(raw) : {}
//       console.table(data)
//       return data
//     },
//     setItem: async (key: string, value: any) => {
//       const valueString = JSON.stringify(value)
//       localForage.setItem(key, valueString)
//     },
//     removeItem: async (key: string) => localForage.removeItem(key),
//   },
//   asyncStorage: true,
//   modules: [
//     // IMPORTANT:
//     // Do not add 'auth' here, it will break the navigation guard...
//     'profile',
//     'profileSettings',
//     'device',
//     'deviceSettings',
//     'sync',
//     'ui',
//     'version',
//     // Modules
//     // 'dreams.dreams',
//     // 'focus.goals',
//     // 'gems.gems',
//     // 'ideas.ideas',
//     // 'journal.entries',
//     // 'journal.bullets',
//     // 'notes.notes',
//     // 'notes.noteLabels',
//     // 'people.people',
//     // 'people.personGroups',
//     // 'tags.tags',
//     // 'tags.tagGroups',
//     // 'todos.todos',
//     'dreams',
//   ],
// }).plugin

export const store = createStore({
  strict: false, // import.meta.env.NODE_ENV !== 'production' NEVER SET THIS TO TRUE IN PROCUTION!!!
  actions,
  getters,
  mutations,
  state,
  modules: {
    dreams: {
      namespaced: true,
      state: dreamsState,
      getters: dreamsGetters,
      mutations: dreamsMutations,
      actions: dreamsActions,
    },
    focus: {
      namespaced: true,
      state: focusState,
      getters: focusGetters,
      mutations: focusMutations,
      actions: focusActions,
    },
    gems: {
      namespaced: true,
      state: gemsState,
      getters: gemsGetters,
      mutations: gemsMutations,
      actions: gemsActions,
    },
    ideas: {
      namespaced: true,
      state: ideasState,
      getters: ideasGetters,
      mutations: ideasMutations,
      actions: ideasActions,
    },
    journal: {
      namespaced: true,
      state: journalState,
      getters: journalGetters,
      mutations: journalMutations,
      actions: journalActions,
    },
    notes: {
      namespaced: true,
      state: notesState,
      getters: notesGetters,
      mutations: notesMutations,
      actions: notesActions,
    },
    people: {
      namespaced: true,
      state: peopleState,
      getters: peopleGetters,
      mutations: peopleMutations,
      actions: peopleActions,
    },
    tags: {
      namespaced: true,
      state: tagsState,
      getters: tagsGetters,
      mutations: tagsMutations,
      actions: tagsActions,
    },
    todos: {
      namespaced: true,
      state: todosState,
      getters: todosGetters,
      mutations: todosMutations,
      actions: todosActions,
    },
  },
  plugins: [vuexPersistedStatePlugin],
})
