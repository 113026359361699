export default defineNuxtPlugin(() => {
  const router = useRouter()

  // Redirects

  router.addRoute({
    path: '/',
    redirect: to => ({ path: '/write', query: to.query }),
  })
  router.addRoute({
    path: '/notes/create',
    redirect: to => ({ path: `/notes/${uuid4()}`, query: to.query }),
  })
  router.addRoute({
    path: '/focus/create',
    redirect: to => ({ path: `/focus/${uuid4()}`, query: to.query }),
  })
  router.addRoute({
    path: '/wisdom/create',
    redirect: to => ({ path: `/wisdom/${uuid4()}`, query: to.query }),
  })
  router.addRoute({
    path: '/ideas/create',
    redirect: to => ({ path: `/ideas/${uuid4()}`, query: to.query }),
  })

  // Store scroll position of scroll element before leaving the route.

  const scrollElementSelector = '#page-content'
  const scrollPositions = useState('savedScrollPositions', (): Record<string, ScrollPosition> => ({}))

  router.beforeEach((_to, from, next) => {
    const scrollElement = document.querySelector(scrollElementSelector)
    if (scrollElement) {
      scrollPositions.value[from.fullPath] = {
        top: scrollElement.scrollTop,
        left: scrollElement.scrollLeft,
      }
    }
    next()
  })
})
