/**
 * Tokens
 */

// Matches an alphanumeric character in any language.
// Note: '-' always needs to be the last item in a set, otherwise it depicts a range.
export const reAlhpaNumeric = '\\p{L}\\p{N}_-'
// Matches a word boundary in any language, including at the beginning and end
// of the string. Needs to exclude # and @ to make auto-tagging/mentioning work.
export const reWordBoundary = `([^#@${reAlhpaNumeric}]|^|$)`

/**
 * General patterns
 */

export const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i
export const reLink = /(https?:\/\/(www\.)?[-\w@:%.+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-\w()@:%+.~#?&/=]*))/gi

/**
 * Internal patterns
 */

export const reTagNew = new RegExp(`#([${reAlhpaNumeric}]+)`, 'giu')
export const rePersonNew = new RegExp(`@([${reAlhpaNumeric}]+)`, 'giu')
