export default defineAppConfig({
  nuxtIcon: {
    size: '1em',
    aliases: {
      'freud': 'bx-brain',
      'davinci': 'material-symbols:ecg-heart-outline',
      'voynich': 'mdi:safe-square-outline',
      'oura': 'IconOura',
      'gem': 'fa6-solid:gem',
      'loading': 'fa6-solid:spinner',
      'refresh': 'ph:arrows-clockwise-fill',
      'syncing': 'ph:arrows-clockwise-fill',
      'theme-light': 'fa6-solid:sun',
      'theme-dark': 'fa6-solid:moon',
      'theme-auto': 'fa6-solid:circle-half-stroke',
      'back': 'fa6-solid:arrow-left',
      'next': 'fa6-solid:arrow-right',
      'search': 'fa6-solid:magnifying-glass',
      'plus': 'fa6-solid:plus',
      'minus': 'fa6-solid:minus',
      'plus-minus': 'fa6-solid:plus-minus',
      'cross': 'fa6-solid:xmark',
      'clear': 'fa6-solid:eraser',
      'trash': 'fa6-solid:trash',
      'mobile-device': 'fa6-solid:mobile-screen',
      'desktop-device': 'fa6-solid:desktop',
      'synced': 'fa6-solid:cloud-arrow-up',
      'write': 'fa6-solid:feather-pointed',
      'journal': 'fa6-solid:book-bookmark',
      'look-ahead': 'fa6-solid:seedling',
      'reflect': 'fa6-solid:clock-rotate-left',
      'insights': 'fa6-solid:chart-simple',
      'dream': 'fa6-solid:moon',
      'highlight': 'fa6-solid:heart',
      'tag': 'fa6-solid:hashtag',
      'tags': 'fa6-solid:tags',
      'mention': 'fa6-solid:at',
      'note': 'fa6-solid:thumbtack',
      'goal': 'fa6-solid:crosshairs',
      'idea': 'fa6-solid:lightbulb',
      'thought': 'fa6-solid:bolt',
      'quote': 'fa6-solid:quote-right',
      'fact': 'fa6-solid:star-of-life',
      'excerpt': 'fa6-solid:align-left',
      'lesson': 'fa6-solid:graduation-cap',
      'check': 'fa6-solid:check',
      'entry': 'fa6-solid:calendar-day',
      'bullet': 'fa6-solid:circle',
      'bullets': 'fa6-solid:list',
      'favorite': 'fa6-solid:star',
      'favorite-outline': 'fa6-regular:star',
      'root': 'fa6-solid:turn-up',
      'menu': 'fa6-solid:ellipsis-vertical',
      'info': 'fa6-solid:info',
      'experimental': 'ph:flask-bold',
      'link': 'fa6-solid:link',
      'list': 'fa6-solid:list',
      'upgrade': 'ph:arrow-up-bold',
      'sparkle': 'ph:sparkle-bold',
      'forward': 'fa6-solid:forward',
      'backward': 'fa6-solid:backward',
      'readiness': 'fa6-solid:seedling',
      'sleep': 'fa6-solid:bed',
      'activity': 'fa6-solid:person-running',
    },
  },
})
