import type { DreamState } from './state'

export default {
  dreams(state: DreamState): Dream[] {
    return state.dreams
  },
  dreamsDict(state: DreamState): Record<DreamId, Dream> {
    return state.dreamsDict
  },
  dreamsCount(state: DreamState): number {
    return state.dreams?.length || 0
  },
}
