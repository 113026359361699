export enum ActionTypes {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  ACTION = 'action',
}

export enum ActionStates {
  PENDING = 'pending',
  PROCESSING = 'processing',
  ERROR = 'error',
}

// Defines the different ways a push can be triggered.
export enum PushTriggers {
  UPDATE = 'update', // Triggered by an update.
  LOOP = 'loop', // Triggered after a push completes.
  MANUAL = 'manual', // Triggered manually by the user.
  AUTO = 'auto', // Triggered periodically by the UI.
}

export type PushTrigger = `${PushTriggers}`

// Device status of the 'push actions' flow.
export enum PushStates {
  IDLE = 'idle',
  PROCESSING = 'processing',
  ERROR = 'error',
}

export type PushState = `${PushStates}`

// Server status of the sync process.
export enum SyncStates {
  OUTDATED = 'outdated',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}

export type SyncState = `${SyncStates}`

export function generateAction(data: Omit<Action, 'id' | 'timestamp' | 'state'>): Action {
  return Object.assign(
    {
      id: uuid4(),
      timestamp: new Date().toISOString(),
      state: ActionStates.PENDING,
    },
    structuredClone(data), // structuredClone generates a deep copy of the object.
  )
}
