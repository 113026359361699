export interface GemState {
  gems: Gem[]
  gemsDict: { [id: GemId]: Gem }
}

export const defaultState: GemState = ({
  gems: [],
  gemsDict: {},
})

export default () => Object.assign({}, defaultState)
