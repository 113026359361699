import type { ActionContext } from 'vuex'
import type {
  Device, DeviceSettings, Profile, ProfileSettings, RootState, ServerChange,
  SyncStatus, UI, UIx,
} from './state'
import type { Model, Module } from '~/utils/common'
import { getModelDictName } from '~/utils/common'
import type { PushTrigger, SyncState } from '~/utils/sync'
import {
  ActionStates, PushStates, PushTriggers, SyncStates,
} from '~/utils/sync'
import {
  defaultSyncState, defaultUIState,
  defaultVersionState,
} from './state'

interface SyncedAction {
  action_id: ActionId
  action_type: ActionType
  module: Module
  model: Model
  object_id: UUID
  data: any
}

interface SyncError {
  action_id: ActionId
  action_type: ActionType
  module: Module
  model: Model
  object_id: UUID
  status_code: number
  error_message: string
}

interface SyncResponse {
  sync_id: SyncId
  sync_sts: DateTimeString
  status: SyncState
  synced_actions: SyncedAction[]
  errors: SyncError[]
}

export default {
  /**
   * Add new keys to critical state dicts.
   */
  repairState({ commit, state }: ActionContext<RootState, RootState>): void {
    console.info(':: Repairing state')
    commit('updateState', {
      sync: Object.assign(defaultSyncState, state.sync),
      ui: Object.assign(defaultUIState, state.ui),
      version: Object.assign(defaultVersionState, state.version),
    })
  },
  /**
   * Fetch the sync status from the backend.
   */
  async fetchSyncStatus(): Promise<SyncStatus> {
    return await $http.$get('/sync/status/')
  },
  /**
   * Fetch profile data from the backend.
   */
  async fetchProfile({ commit, state }: ActionContext<RootState, RootState>): Promise<Profile> {
    const data: Profile & Partial<{
      profile_settings: ProfileSettings
      device_settings: DeviceSettings
    }> = await $http.$get('/user/profile/')
    // Populate profile settings from DB.
    commit('populateProfileSettings', data.profile_settings)
    delete data.profile_settings
    // Populate device settings. If there are local device settings already,
    // use them and complement them with the full set of defaults.
    // Otherwise, initially populate them with the ones stored in the DB
    // and also complement them with the full set of defaults.
    if (!state.deviceSettings) {
      commit('populateDeviceSettings', data.device_settings)
    }
    delete data.device_settings
    commit('populateProfile', data)
    return data
  },
  /**
   * Fetch all data from the server for a full refresh.
   * Should not be used for normal data sync, use syncData() for that.
   */
  async refreshData({ commit, dispatch, state }: ActionContext<RootState, RootState>): Promise<void> {
    await dispatch('repairState')
    console.info(':: Refreshing data')
    const data = await Promise.all([
      dispatch('fetchSyncStatus'),
      dispatch('fetchProfile'),
      dispatch('journal/fetchData', {}, { root: true }),
      dispatch('dreams/fetchData', {}, { root: true }),
      dispatch('tags/fetchData', {}, { root: true }),
      dispatch('people/fetchData', {}, { root: true }),
      dispatch('notes/fetchData', {}, { root: true }),
      dispatch('focus/fetchData', {}, { root: true }),
      dispatch('gems/fetchData', {}, { root: true }),
      dispatch('ideas/fetchData', {}, { root: true }),
      dispatch('todos/fetchData', {}, { root: true }),
    ])
    const syncStatus = data[0]
    commit('setSync', {
      id: syncStatus.sync_id,
      sts: syncStatus.sync_sts,
      actionQueue: state.sync.actionQueue,
      actionsDict: state.sync.actionsDict,
      pushState: PushStates.IDLE,
    })
    commit('setVersion', {
      pwa: syncStatus.pwa_version,
      db: syncStatus.db_version,
    })
    return Promise.resolve()
  },
  /**
   * Efficiently sync data with the server.
   * This is the default!
   */
  async syncData({ commit, dispatch, state }: ActionContext<RootState, RootState>): Promise<void> {
    console.info(':: Syncing data')
    const syncStatus = await dispatch('fetchSyncStatus')
    console.log('[device]', state.sync?.id?.slice(30), state.sync?.sts)
    console.log('[server]', syncStatus.sync_id?.slice(30), syncStatus.sync_sts)
    if (
      (syncStatus.db_version && syncStatus.db_version !== state.version.db)
      || (state.profileSettings?.action_sync_enabled && state.sync && (!state.sync.id || !state.sync.sts))
    ) {
      // If a new version with a database schema change was deployed or if the
      // device doesn't have sync ID/timestamp yet, refresh all data.
      commit('updateUIx', { updating: true })
      await dispatch('refreshData')
      commit('updateUIx', { updating: false })
    }
    else {
      // Otherwise, perform a normal sync, either the new version if the flag
      // is enabled, otherwise the old one.
      if (state.profileSettings?.action_sync_enabled) {
        // Execute new sync for modules that already use actions.
        console.log('NEW')
        console.log('Sync IDs match?', syncStatus.sync_id === state.sync.id)
        if (syncStatus.sync_id !== state.sync.id) {
          // Might be necessary to fetch the Profile here also, not sure if
          // it is covered yet by the new sync.
          console.log('› fetch updates')
          commit('updateUIx', { syncing: true })
          await dispatch('fetchUpdates')
        }
        commit('updateUIx', { updating: false, syncing: false })
        console.log('› push actions')
        await dispatch('pushActions', { trigger: PushTriggers.AUTO })
      }
      else {
        console.log('DEFAULT')
        const lastUpdateSts = state.profile?.last_update_sts
        console.log('lastUpdateDt device:', lastUpdateSts)
        console.log('lastUpdateDt server:', syncStatus.last_update_sts)
        if (!lastUpdateSts || (syncStatus.last_update_sts && syncStatus.last_update_sts > lastUpdateSts)) {
          commit('updateUIx', { syncing: true })
          await dispatch('refreshData', { skipActions: true })
        }
        commit('updateUIx', { updating: false, syncing: false })
      }
      return Promise.resolve()
    }
  },
  /**
   * Fetch updates from the server that were generated by other devices.
   * This is used only for modules using the action queue.
   */
  async fetchUpdates({ commit, dispatch, state }: ActionContext<RootState, RootState>): Promise<void> {
    const res: {
      sync_id: string
      sync_sts: DateTimeString
      changes: ServerChange[]
    } = await $http
      .$post('/sync/fetch-updates/', {
        body: {
          sync_id: state.sync.id,
          sync_sts: state.sync.sts,
        },
      })
    console.log('fetchUpdates res:')
    console.table(res.changes)

    // Create a map with object_id -> action to check if any of the changes
    // affect an action.
    const objectActionMap: { [objectId: UUID]: Action[] } = {}
    state.sync.actionQueue.forEach((action) => {
      if (objectActionMap[action.data.id]) {
        objectActionMap[action.data.id]?.push(action)
      }
      else {
        objectActionMap[action.data.id] = [action]
      }
    })

    const conflicts: {
      actionId: ActionId
      change: ServerChange
    }[] = []

    res.changes.forEach((change) => {
      // Check if the change affects one or more actions and if yes, add them
      // to the conflicts dict.
      if (objectActionMap[change.data.id]) {
        objectActionMap[change.data.id]?.forEach(action => conflicts.push({ actionId: action.id, change }))
      }
      if (change.action_type === 'update') {
        const obj = (state[change.module as keyof RootState] as any)[getModelDictName(change.model)][change.data.id]
        const actionType = obj ? 'update' : 'create'
        const actionCall = `${change.module}/${actionType}${change.model}`
        console.log(actionCall, change.sts, change.data)
        commit(actionCall, change.data, { root: true })
      }
      else if (change.action_type === 'delete') {
        const actionCall = `${change.module}/delete${change.model}`
        console.log(actionCall, change.sts, change.data)
        commit(actionCall, change.data.object_id)
      }
    })

    console.log('CONFLICTS', conflicts)

    // Update the syncing state meta. DO NOT update db_version here to make
    // sure a refresh is always triggered at some point.
    commit('updateSync', {
      id: res.sync_id,
      sts: res.sync_sts,
      conflicts,
    }, { root: true })

    // For now just resolve conflicts without asking.
    if (conflicts.length) {
      dispatch('resolveConflicts')
    }
  },
  async pushActions(
    { commit, dispatch, state }: ActionContext<RootState, RootState>,
    options: { trigger?: PushTrigger, limit?: number } = {},
  ): Promise<void> {
    if (!state.sync.actionQueue.length) {
      console.log('Nothing to push...')
    // TODO [N3] Figure out what to do here...
    // } else if (this._vm.$nuxt.isOffline) {
    //   console.log('Device offline, skipping push...')
    }
    else if (state.deviceSettings?.manual_push && options.trigger !== PushTriggers.MANUAL) {
      console.log('Manual mode, skipping push...')
    }
    else if (state.sync.pushState === PushStates.PROCESSING) {
      console.log('Sync ongoing, skipping push...')
    }
    else {
      // Get the actions that need to be processd.
      const actions = state.sync.actionQueue
        .filter(action => action.state !== ActionStates.PROCESSING)
        .slice(0, options.limit || 1000)
      console.info(`:: Pushing ${actions.length} action/s`)
      actions.forEach((action) => {
        console.log(`- ${action.action_type} ${action.model}`)
      })
      // Set 'processing' flags.
      commit('updateSync', { pushState: PushStates.PROCESSING })
      actions.forEach((action) => {
        commit('updateAction', { id: action.id, status: ActionStates.PROCESSING })
      })
      // Attempt the push and repeat fetching updates until we're good.
      let res: SyncResponse
      while (true) {
        res = await $http
          .$post('/sync/push-actions/', {
            body: {
              sync_id: state.sync.id,
              actions,
            },
          })
        if (res.status === SyncStates.OUTDATED)
          await dispatch('fetchUpdates')
        else break
      }
      if (res.errors.length) {
        console.log('Push returned errors:')
        res.errors.forEach((item: SyncError) => {
          console.log('ERROR', item.model, item.action_type, item.status_code, item.error_message)
          commit('updateAction', { id: item.action_id, state: ActionStates.ERROR })
        })
        commit('updateSync', {
          pushState: PushStates.ERROR,
        })
        commit('updateUIx', {
          indicator: {
            icon: 'fa6-solid:triangle-exclamation',
            label: 'Push Error',
            type: 'error',
          },
        })
        return Promise.resolve()
      }
      console.log('Processing synced actions:')
      res.synced_actions.forEach((item: SyncedAction) => {
        // Drop the item from the sync queue and update the 'synced' flag
        // in case the object was created or updated.
        console.log('SUCCESS', item.model, item.action_type, item)
        commit('deleteAction', item.action_id)
        if (['create', 'update'].includes(item.action_type)) {
          commit(`${item.module}/update${item.model}`, { id: item.object_id, synced: true }, { root: true })
        }
      })
      state.sync.actionQueue.forEach((action) => {
        if (action.state === ActionStates.PROCESSING) {
          commit('updateAction', { id: action.id, state: ActionStates.PENDING })
        }
      })
      commit('updateSync', {
        id: res.sync_id,
        sts: res.sync_sts,
        pushState: PushStates.IDLE,
      })
      // If there is no limit set, wait one second, then push again until
      // there are no actions left in the queue...
      if (!options.limit) {
        await setTimeout(() => {}, 1000)
        dispatch('pushActions', { trigger: PushTriggers.LOOP })
      }
    }
    return Promise.resolve()
  },
  resolveConflicts({ commit, dispatch, state }: ActionContext<RootState, RootState>): void {
    // For now, keep it simple and just delete all conflicting actions and
    // trigger a full refresh.
    console.log('resolveConflicts')
    state.sync.conflicts.forEach(conflict => commit('deleteAction', conflict.actionId))
    commit('updateSync', { conflicts: [] })
    dispatch('refreshData')
  },
  resetSync({ commit, state }: ActionContext<RootState, RootState>): void {
    state.sync.actionQueue.forEach((action) => {
      if (action.state === ActionStates.PROCESSING) {
        commit('updateAction', { id: action.id, state: ActionStates.PENDING })
      }
    })
    commit('updateSync', { pushState: PushStates.IDLE })
  },
  clearSyncQueue({ commit }: ActionContext<RootState, RootState>): void {
    commit('clearSyncQueue')
  },
  resetDeviceState({ commit }: ActionContext<RootState, RootState>): void {
    commit('resetDeviceState')
  },
  // syncProfile({ commit, dispatch, state }) {
  //   console.info('Syncing profile...')
  //   return $http
  //     .$get('/sync-info/')
  //     .then(res => {
  //       const last_sync_dt = state.profile?.last_update_sts
  //       if (!last_sync_dt || (res.last_update_sts && res.last_update_sts > last_sync_dt)) {
  //         commit('updateUIx', { syncing: true })
  //         dispatch('fetchProfile')
  //             .finally(() => commit('updateUIx', { syncing: false }))
  //       } else {
  //         commit('updateUIx', { syncing: false })
  //       }
  //     })
  // },
  // syncAccountStatus({ commit }: ActionContext<RootState, RootState>): Promise<any> {
  //   console.info('Syncing account status...')
  //   return $http
  //     .$get('/user/profile/')
  //     .then((res: any) => {
  //       commit('updateProfile', {
  //         account_type: res.account_type,
  //         account_type_name: res.account_type_name,
  //         pro: res.pro,
  //         stripe_customer_id: res.stripe_customer_id,
  //         stripe_active_subscriptions: res.stripe_active_subscriptions,
  //       })
  //     })
  //     .catch((error: any) => console.error('syncAccountStatus', error))
  // },
  updateProfile({ commit }: ActionContext<RootState, RootState>, profileData: Pick<Profile, 'id'>): void {
    commit('updateProfile', profileData)
    // Push to server.
    $http
      .$patch('/user/profile/', { body: profileData })
      .catch((error: Error) => { console.error('updateProfile', error) })
  },
  resetProfile({ commit }: ActionContext<RootState, RootState>) {
    commit('resetProfile')
  },
  updateDevice({ commit }: ActionContext<RootState, RootState>, device: Partial<Device>): void {
    console.time('[A] updateDevice')
    commit('updateDevice', device)
    console.timeEnd('[A] updateDevice')
  },
  updateProfileSettings(
    { state, commit }: ActionContext<RootState, RootState>,
    profileSettings: Partial<ProfileSettings>,
  ): void {
    commit('updateProfileSettings', profileSettings)
    $http
      .$patch('/user/profile/', {
        body: {
          profile_settings: Object.assign({}, state.profileSettings, profileSettings),
        },
      })
      .catch((error: Error) => { console.error('updateProfileSettings', error) })
  },
  updateDeviceSettings(
    { state, commit }: ActionContext<RootState, RootState>,
    deviceSettings: Partial<DeviceSettings>,
  ): void {
    commit('updateDeviceSettings', deviceSettings)
    $http
      .$patch('/user/profile/', {
        body: {
          device_settings: Object.assign({}, state.deviceSettings, deviceSettings),
        },
      })
      .catch((error: Error) => { console.error('updateDeviceSettings', error) })
  },
  // toggleDarkMode({ state, commit }: ActionContext<RootState, RootState>): void {
  //   let payload
  //   if (state.deviceSettings?.dark_mode_auto) {
  //     payload = {
  //       dark_mode: false,
  //       dark_mode_auto: false,
  //     }
  //   } else if (state.deviceSettings?.dark_mode) {
  //     payload = {
  //       dark_mode: false,
  //       dark_mode_auto: true,
  //     }
  //   } else {
  //     payload = {
  //       dark_mode: true,
  //     }
  //   }
  //   commit('updateDeviceSettings', payload)
  // },
  disconnectProvider({ state, commit }: ActionContext<RootState, RootState>, provider: string): void {
    if (state.profile) {
      $http
        .$put('/user/profile/disconnect-provider/', { body: { provider } })
        .then((profile: Profile) => commit('updateProfile', profile))
    }
  },
  updateUI({ commit }: ActionContext<RootState, RootState>, ui: Partial<UI>): void {
    console.time('[A] updateUI')
    commit('updateUI', ui)
    console.timeEnd('[A] updateUI')
  },
  updateUIx({ commit }: ActionContext<RootState, RootState>, uix: Partial<UIx>): void {
    console.time('[A] updateUIx')
    commit('updateUIx', uix)
    console.timeEnd('[A] updateUIx')
  },
  clearRandomGem({ commit }: ActionContext<RootState, RootState>): void {
    commit('clearRandomGem')
  },
  clearRandomIdea({ commit }: ActionContext<RootState, RootState>): void {
    commit('clearRandomIdea')
  },
  /** Reset the original state of the main store (layout, UI). */
  resetState({ commit }: ActionContext<RootState, RootState>): void {
    commit('resetState')
  },
  /** Reset data of store modules, keep settings and layout state. */
  resetData({ dispatch }: ActionContext<RootState, RootState>): Promise<any> {
    return Promise.all([
      dispatch('resetProfile'),
      dispatch('journal/resetState', {}, { root: true }),
      dispatch('dreams/resetState', {}, { root: true }),
      dispatch('tags/resetState', {}, { root: true }),
      dispatch('people/resetState', {}, { root: true }),
      dispatch('focus/resetState', {}, { root: true }),
      dispatch('notes/resetState', {}, { root: true }),
      dispatch('gems/resetState', {}, { root: true }),
      dispatch('ideas/resetState', {}, { root: true }),
      dispatch('todos/resetState', {}, { root: true }),
    ])
  },
  resetSettings({ commit }: ActionContext<RootState, RootState>) {
    commit('resetSettings')
  },
}
