export interface NoteState {
  notes: Note[]
  notesDict: { [id: NoteId]: Note }
  noteLabels: NoteLabel[]
  noteLabelsDict: { [id: NoteLabelId]: NoteLabel }
}

export const defaultState: NoteState = ({
  notes: [],
  notesDict: {},
  noteLabels: [],
  noteLabelsDict: {},
})

export default () => Object.assign({}, defaultState)
