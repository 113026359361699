import type { NoteState } from './state'

export default {
  notes(state: NoteState): Note[] {
    return state.notes
  },
  notesDict(state: NoteState): Record<NoteId, Note> {
    return state.notesDict
  },
  notesCount(state: NoteState): number {
    return state.notes ? state.notes.length : 0
  },
  noteLabels(state: NoteState): NoteLabel[] {
    return state.noteLabels
  },
  noteLabelsDict(state: NoteState): Record<NoteLabelId, NoteLabel> {
    return state.noteLabelsDict
  },
  noteLabelsCount(state: NoteState): number {
    return state.noteLabels ? state.noteLabels.length : 0
  },
}
