import type { JournalState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: JournalState) {
    Object.assign(state, defaultState)
  },
  /**
   * Entries
   */
  populateEntriesDict(state: JournalState): void {
    state.entriesDict = listMapper(state.entries)
  },
  updateEntries(state: JournalState, entriesData: Entry[]): void {
    state.entries = entriesData
    state.entriesDict = listMapper(state.entries)
  },
  createEntry(state: JournalState, data: Entry): void {
    if (!state.entriesDict[data.id]) {
      // Find the correct index where to insert the new Entry based on its date.
      const entryDate = data.date
      let targetIdx = 0
      for (const entry of state.entries) {
        if (entry.date < entryDate) {
          break
        }
        targetIdx++
      }
      state.entriesDict[data.id] = data
      state.entries.splice(targetIdx, 0, data)
    }
  },
  updateEntry(state: JournalState, data: Pick<Entry, 'id'> & Partial<Entry>): void {
    const objId = data.id
    const idx = state.entries.findIndex(entry => entry.id === objId)
    const origObj = structuredClone(toRaw(state.entries[idx])) as Entry
    const newObj = Object.assign({}, origObj, data)
    state.entries[idx] = newObj
    state.entriesDict[objId] = newObj
  },
  deleteEntry(state: JournalState, entryId: EntryId): void {
    const entry = state.entriesDict[entryId]
    if (entry) {
      state.entries.splice(state.entries.findIndex(obj => obj.id === entryId), 1)
      delete state.entriesDict[entryId]
    }
  },
  // Add/remove dream
  addDreamToEntry(state: JournalState, data: { entryId: EntryId, dreamId: DreamId }): void {
    state.entriesDict[data.entryId]?.dreams.push(data.dreamId)
  },
  removeDreamFromEntry(state: JournalState, data: { entryId: EntryId, dreamId: DreamId }): void {
    const entry = state.entriesDict[data.entryId]
    if (entry) {
      const idx = entry.dreams.indexOf(data.dreamId)
      if (idx !== -1) {
        entry.dreams.splice(idx, 1)
      }
    }
  },
  // Add/remove bullet
  addBulletToEntry(state: JournalState, data: { entryId: EntryId, bulletId: BulletId }): void {
    // Make sure to keep the order.
    console.time('[m] addBulletToEntry')
    const entry = state.entriesDict[data.entryId]
    const bullet = state.bulletsDict[data.bulletId]
    if (entry && bullet) {
      const position = bullet.position
      let targetIdx = 0
      for (const bulletId of entry.bullets) {
        if (state.bulletsDict[bulletId]!.position > position) {
          break
        }
        targetIdx++
      }
      entry.bullets.splice(targetIdx, 0, bullet.id)
    }
    console.timeEnd('[m] addBulletToEntry')
  },
  removeBulletFromEntry(state: JournalState, data: { entryId: EntryId, bulletId: BulletId }): void {
    console.time('[m] removeBulletToEntry')
    const entry = state.entriesDict[data.entryId]
    if (entry) {
      const idx = entry.bullets.indexOf(data.bulletId)
      if (idx !== -1) {
        entry.bullets.splice(idx, 1)
      }
    }
    console.timeEnd('[m] removeBulletToEntry')
  },
  // Add/remove note
  addNoteToEntry(state: JournalState, data: { entryId: EntryId, noteId: NoteId }): void {
    state.entriesDict[data.entryId]?.notes.push(data.noteId)
  },
  removeNoteFromEntry(state: JournalState, data: { entryId: EntryId, noteId: NoteId }): void {
    // Make sure entry still exists.
    if (state.entriesDict[data.entryId]) {
      const idx = state.entriesDict[data.entryId]?.notes.indexOf(data.noteId)
      if (idx !== undefined && idx !== -1)
        state.entriesDict[data.entryId]?.notes.splice(idx, 1)
    }
  },
  // Add/remove gem
  addGemToEntry(state: JournalState, data: { entryId: EntryId, gemId: GemId }): void {
    state.entriesDict[data.entryId]?.gems.push(data.gemId)
  },
  removeGemFromEntry(state: JournalState, data: { entryId: EntryId, gemId: GemId }): void {
    // Make sure entry still exists.
    if (state.entriesDict[data.entryId]) {
      const idx = state.entriesDict[data.entryId]?.gems.indexOf(data.gemId)
      if (idx !== undefined && idx !== -1)
        state.entriesDict[data.entryId]?.gems.splice(idx, 1)
    }
  },
  // Add/remove idea
  addIdeaToEntry(state: JournalState, data: { entryId: EntryId, ideaId: IdeaId }): void {
    state.entriesDict[data.entryId]?.ideas.push(data.ideaId)
  },
  removeIdeaFromEntry(state: JournalState, data: { entryId: EntryId, ideaId: IdeaId }): void {
    // Make sure entry still exists.
    if (state.entriesDict[data.entryId]) {
      const idx = state.entriesDict[data.entryId]?.ideas.indexOf(data.ideaId)
      if (idx !== undefined && idx !== -1)
        state.entriesDict[data.entryId]?.ideas.splice(idx, 1)
    }
  },
  // Images
  deleteImageFromEntry(state: JournalState, data: { entryId: EntryId, imageId: ImageId }): void {
    if (state.entriesDict[data.entryId]) {
      let imgIdx = 0
      for (const img of state.entriesDict[data.entryId]!.images) {
        if (img.id === data.imageId) {
          break
        }
        imgIdx++
      }
      state.entriesDict[data.entryId]?.images.splice(imgIdx, 1)
    }
  },
  /**
   * Bullets
   */
  populateBulletsDict(state: JournalState): void {
    state.bulletsDict = listMapper(state.bullets)
  },
  updateBullets(state: JournalState, bulletsData: Bullet[]): void {
    state.bullets = bulletsData
    state.bulletsDict = listMapper(state.bullets)
  },
  createBullet(state: JournalState, data: Bullet): void {
    console.time('[m] createBullet')
    if (!state.bulletsDict[data.id]) {
      // Find the correct index where to insert the new Bullet based on its position.
      const bulletDate = data.date
      let targetIdx = 0
      for (const bullet of state.bullets) {
        if (bullet.date < bulletDate) {
          break
        }
        targetIdx++
      }
      console.time('splice')
      state.bullets.splice(targetIdx, 0, data)
      console.timeEnd('splice')
      console.time('set')
      state.bulletsDict[data.id] = data
      console.timeEnd('set')
    }
    console.timeEnd('[m] createBullet')
  },
  updateBullet(state: JournalState, data: Pick<Bullet, 'id'> & Partial<Bullet>): void {
    console.time('[m] updateBullet')
    const objId = data.id
    const idx = state.bullets.findIndex(bullet => bullet.id === objId)
    const origObj = structuredClone(toRaw(state.bullets[idx])) as Bullet
    const newObj = Object.assign(origObj, data) // Allows partial update of object.
    state.bullets[idx] = newObj
    state.bulletsDict[objId] = newObj
    // Sort the bullet list of the entry.
    if (data.position) {
      const entry = state.entriesDict[newObj.entry]
      entry?.bullets.sort((a, b) => {
        const x = state.bulletsDict[a]!.position
        const y = state.bulletsDict[b]!.position
        if (x < y)
          return -1
        if (x > y)
          return 1
        return 0
      })
    }
    console.timeEnd('[m] updateBullet')
  },
  deleteBullet(state: JournalState, bulletId: BulletId): void {
    console.time('[m] deleteBullet')
    const bullet = state.bulletsDict[bulletId]
    if (bullet) {
      state.bullets.splice(state.bullets.findIndex(bullet => bullet.id === bulletId), 1)
      delete state.bulletsDict[bulletId]
    }
    console.timeEnd('[m] deleteBullet')
  },
  addTagToBullet(state: JournalState, data: { bulletId: BulletId, tagId: TagId, index?: number }): void {
    console.time('[m] addTagToBullet')
    const bullet = state.bulletsDict[data.bulletId]
    if (bullet) {
      bullet.tags.splice(data.index || 1e6, 0, data.tagId)
    }
    console.timeEnd('[m] addTagToBullet')
  },
  removeTagFromBullet(state: JournalState, data: { bulletId: BulletId, tagId: TagId }): void {
    console.time('[m] removeTagToBullet')
    const bullet = state.bulletsDict[data.bulletId]
    bullet?.tags.splice(bullet.tags.indexOf(data.tagId), 1)
    console.timeEnd('[m] removeTagToBullet')
  },
  addPersonToBullet(state: JournalState, data: { bulletId: BulletId, personId: TagId, index?: number }): void {
    const bullet = state.bulletsDict[data.bulletId]
    if (bullet) {
      bullet.people.splice(data.index || 1e6, 0, data.personId)
    }
  },
  removePersonFromBullet(state: JournalState, data: { bulletId: BulletId, personId: TagId }): void {
    const bullet = state.bulletsDict[data.bulletId]
    bullet?.people.splice(bullet.people.indexOf(data.personId), 1)
  },
}
