import type { GemState } from './state'

export default {
  gems(state: GemState): Gem[] {
    return state.gems
  },
  gemsDict(state: GemState): Record<GemId, Gem> {
    return state.gemsDict
  },
  gemsCount(state: GemState): number {
    return state.gems ? state.gems.length : 0
  },
  firstGemDate(state: GemState): DateString | undefined {
    return state.gems?.length
      ? state.gems[state.gems.length - 1].created_dts.substring(0, 10)
      : undefined
  },
}
