<script setup lang="ts">
import { useStore } from 'vuex'

const config = useRuntimeConfig()
const colorMode = useColorMode()
const store = useStore()

const vh = ref<string>('1vh')
const ih = ref<string>('100vh')

const storeReady = computed(() => store.state.ready)

const htmlStyles = computed(() => ([
  `--root-font-size: ${store.getters.deviceSettings?.font_size || 16}px; `,
  `--vh: ${vh.value}; `,
  `--ih: ${ih.value};`,
].join(' ')))

const bodyClasses = computed(() => ([
  'theme--base',
  `theme--${colorMode.value}`,
  'theme-processor',
  store.getters.deviceSettings?.mark_unsynced_objects ? ' mark-unsynced-objects' : '',
  store.getters.uix?.inputActive ? ' input-active' : '',
  store.getters.uix?.dialogOpen ? ' dialog-open noscroll' : '',
  store.getters.ui?.sideMenuOpen ? ' noscroll-mobile' : '',
].join(' ')))

useHead({
  meta: [{
    id: 'theme-color',
    name: 'theme-color',
    content: () => (
      colorMode.value === 'dark'
        ? '#191a1a'
        : '#ffffff'
    ),
  }],
  htmlAttrs: {
    style: htmlStyles,
  },
  bodyAttrs: {
    class: () => bodyClasses.value,
  },
})

const mobile = ref<boolean | undefined>(undefined)

function setMobile() {
  // The timeout is necessary to make it work correctly.
  // Without the timeout the size will be determined before the resize is
  // complete e.g. when switching between mobile/desktop in Chrome dev tools.
  // Note: check if the trailing flag could be used instead:
  // https://github.com/vueuse/vueuse/blob/main/packages/shared/useThrottleFn/index.ts
  setTimeout(() => mobile.value = window.innerWidth < 1024, 15)
}
function setLocale() {
  store.dispatch('updateUIx', { locale: window?.navigator?.language || 'en_US' })
}
/**
 * Determine if the app is opened in TWA mode and store the result in the
 * device state. `packageName` corresponds to the ID
 * of the app on the Play Store. This is important to differentiate from the page
 * being opened from a Custom Tab, for instance.
 */
function setTWA() {
  // Note: put this back in case the detection doesn't work reliably.
  // if (store.getters.device.twa) {
  //   // If the TWA flag is already set to 'true', all good, nothing to do...
  //   return
  // }
  const twaProtocol = 'android-app:'
  const referrer = document.referrer.trim()
  const referrerUrl = referrer ? new URL(referrer) : undefined
  const twaReferrer = !!(referrerUrl && referrerUrl.protocol === twaProtocol && referrerUrl.hostname === config.public.packageName)
  const twaQueryParam: boolean = location.href.includes('twa=true')
  const standaloneQueryParam: boolean = location.href.includes('standalone=true')

  const twa = !!(twaReferrer || twaQueryParam)

  console.info(':: TWA check')
  console.log('referrer:', twaReferrer)
  console.log('query:', `{ twa: ${twaQueryParam}, standalone: ${standaloneQueryParam} }`)

  if (store.getters.device.twa !== twa) {
    store.dispatch('updateDevice', { twa })
  }
}
/**
 * Dynamically set vh property.
 * Fix for vh change when brower nav appears/disappears on mobile browsers.
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function setViewHeightVars() {
  vh.value = `${document.documentElement.clientHeight * 0.01}px`
  ih.value = window.visualViewport ? (`${window.visualViewport.height}px`) : '100vh'
}

useEventListener(window.visualViewport, 'resize', useDebounceFn(setViewHeightVars, 50))
useEventListener(window.visualViewport, 'resize', useThrottleFn(setMobile, 100, true))

watch(() => mobile.value, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    store.dispatch('updateUIx', { mobile: newVal })
    // Close sidemenu when switching to mobile.
    if (newVal && store.getters.ui.sideMenuOpen) {
      store.dispatch('updateUI', { sideMenuOpen: false })
    }
  }
})

onMounted(() => {
  setMobile()
  setLocale()
  setTWA()
  setViewHeightVars()
})
</script>

<template>
  <div id="wrapper">
    <NuxtLayout v-if="storeReady">
      <NuxtPage :keepalive="{}" />
    </NuxtLayout>
    <AppLoadingScreen v-else />
  </div>
</template>
