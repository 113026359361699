<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

definePageMeta({
  layout: 'blank',
})

const config = useRuntimeConfig()

const code = computed(() => props.error.statusCode)
const message = computed(() => code.value === 404 ? 'Page not found' : 'An unexpected error occured')
</script>

<template>
  <div class="container">
    <div class="content">
      <div class="code">
        {{ code }}
      </div>
      <h1 class="title">
        {{ message }}
      </h1>
      <div class="info">
        Please check if your URL is correct and try to refresh the page.
        If the problem persists please contact our support at
        <a
          :href="`mailto:${config.public.helpEmail}`"
          class="text-link"
          title="Email support"
        >{{ config.public.helpEmail }}</a>.
      </div>
      <NuxtLink to="/" class="back-link">
        <Icon name="back" /> Back to Journalistic
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .content {
    max-width: $content-max-width;
    padding: 2rem;
    text-align: center;
    .icon {
      font-size: 2.4rem;
    }
    .code {
      color: hsl(var(--text-dim)) !important;
      font-size: 3rem;
      font-weight: bold;
    }
    .title {
      margin: 2rem;
    }
    .info {
      margin-bottom: 2rem;
      line-height: 1.4;
    }
    .back-link {
      margin-right: 1.4rem;
      font-weight: bold;
      svg {
        width: 1.4rem;
      }
    }
  }
}
</style>
