export interface DreamState {
  dreams: Dream[]
  dreamsDict: { [id: DreamId]: Dream }
}

export const defaultState: DreamState = ({
  dreams: [],
  dreamsDict: {},
})

export default () => Object.assign({}, defaultState)
