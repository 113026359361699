export const SORTING_MODE_ICONS: Record<SortingMode, string> = {
  alphabetical: 'fa6-solid:arrow-down-a-z',
  numerical: 'fa6-solid:arrow-down-9-1',
  chronological: 'fa6-regular:clock',
}

/**
 * Sorts a list of objects in place by field.
 * @param sortable - Array to sort.
 * @param field - Field to sort by.
 */
export function sortByField(sortable: any[], field: string, options: { reverse?: boolean } = {}): void {
  const direction = options.reverse ? 1 : -1
  sortable.sort((a, b) => {
    const x = a[field]?.toLowerCase()
    const y = b[field]?.toLowerCase()
    if (x < y)
      return direction
    if (x > y)
      return -direction
    return 0
  })
}

/**
 * Returns a sorted copy of a list.
 * @param {list} sortable - Array to sort.
 * @param {string} field - Field to sort by.
 * @param {boolean} reverse - Reverse order.
 * @returns {list} Sorted copy of the input list.
 */
export function sorted(sortable: any[], field: string, options: { reverse?: boolean } = {}): any[] {
  const sorted = sortable.slice()
  sortByField(sorted, field, options)
  return sorted
}
