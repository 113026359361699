import type { ActionContext } from 'vuex'
import type { GemState } from './state'
import type { RootState } from '~/store/state'

export default {
  resetState({ commit }: ActionContext<GemState, RootState>): void {
    commit('resetState')
  },
  fetchData({ commit }: ActionContext<GemState, RootState>): Promise<void> {
    return $http
      .$get('/gems/')
      .then((data: Gem[]) => commit('loadGems', data))
      .catch((error: any) => console.error('fetchGems', error))
  },
  createGem({ commit, dispatch, rootState }: ActionContext<GemState, RootState>, data: Omit<Gem, 'created_dts' | 'updated_dts'>): Promise<{ gemId: GemId, actionId: ActionId } | void> {
    data.id = data.id || uuid4()
    const timestamp = new Date().toISOString()
    const gem = {
      ...data,
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    // If an entry is submitted, populate the date from the entry,
    // otherwise generate the date and check if a matching entry exists.
    if (gem.entry) {
      gem.date = rootState.journal.entriesDict[gem.entry].date
    }
    else {
      const activeDate = getActiveDateString(rootState.profileSettings?.day_ends_at)
      gem.date = activeDate
      gem.entry = rootState.journal.entries.find(entry => entry.date === activeDate)?.id
    }
    commit('createGem', gem)
    if (gem.entry) {
      commit('journal/addGemToEntry', { entryId: gem.entry, gemId: gem.id }, { root: true })
    }
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.GEMS,
        model: Models.GEM,
        action_type: ActionTypes.CREATE,
        data: gem,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ gemId: data.id, actionId: action.id })
    }
    else {
      return $http
        .$post('gems/', { body: gem })
        .catch((error: any) => console.error('createGem', error))
    }
  },
  updateGem({ commit, dispatch, rootState }: ActionContext<GemState, RootState>, data: Pick<Gem, 'id'> & Partial<Omit<Gem, 'created_dts' | 'updated_dts'>>): Promise<{ actionId: ActionId } | void> {
    const gem = {
      ...data,
      updated_dts: new Date(),
      synced: false,
    }
    commit('updateGem', gem)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.GEMS,
        model: Models.GEM,
        action_type: ActionTypes.UPDATE,
        data: gem,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`gems/${gem.id}/`, { body: gem })
        .catch((error: any) => console.error('updateGem', error))
    }
  },
  addEntryToGem({ commit, dispatch, rootState }: ActionContext<GemState, RootState>, data: { gemId: GemId, entryId: EntryId }): Promise<{ actionId: ActionId } | void> {
    const gemChanges = {
      id: data.gemId,
      entry: data.entryId,
    }
    commit('updateGem', gemChanges)
    commit('journal/addGemToEntry', { entryId: data.entryId, gemId: data.gemId }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.GEMS,
        model: Models.GEM,
        action_type: ActionTypes.UPDATE,
        data: gemChanges,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`gems/${data.gemId}/`, { body: { entry: data.entryId } })
        .catch((error: any) => console.error('addEntryToGem', error))
    }
  },
  removeEntryFromGem({ commit, dispatch, rootState }: ActionContext<GemState, RootState>, data: { gemId: GemId, entryId: EntryId }): Promise<{ actionId: ActionId } | void> {
    const gemChanges = {
      id: data.gemId,
      entry: null,
    }
    commit('updateGem', gemChanges)
    commit('journal/removeGemFromEntry', { entryId: data.entryId, gemId: data.gemId }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.GEMS,
        model: Models.GEM,
        action_type: ActionTypes.UPDATE,
        data: gemChanges,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`gems/${data.gemId}/`, { body: { entry: null } })
        .catch((error: any) => console.error('addEntryToGem', error))
    }
  },
  deleteGem({ commit, dispatch, rootState, state }: ActionContext<GemState, RootState>, gemId: GemId): Promise<{ actionId: ActionId } | void> {
    const gem = state.gemsDict[gemId]
    if (gem.entry) {
      commit('journal/removeGemFromEntry', { entryId: gem.entry, gemId }, { root: true })
    }
    commit('deleteGem', gemId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.GEMS,
        model: Models.GEM,
        action_type: ActionTypes.DELETE,
        data: {
          id: gemId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`gems/${gemId}/`)
        .catch((error: any) => console.error('deleteGem', error))
    }
  },
}
