import type { ActionContext } from 'vuex'
import type { FocusState } from './state'
import type { RootState } from '~/store/state'

export default {
  resetState({ commit }: ActionContext<FocusState, RootState>): void {
    commit('resetState')
  },
  fetchData({ commit }: ActionContext<FocusState, RootState>): Promise<void> {
    return $http
      .$get('/goals/')
      .then((data: Goal[]) => commit('updateGoals', data))
      .catch((error: any) => console.error('fetchGoals', error))
  },
  createGoal({ commit, dispatch, rootState, state }: ActionContext<FocusState, RootState>, data: Omit<Goal, 'priority'>): Promise<{ goalId: GoalId, actionId: ActionId } | void> {
    data.id = data.id || uuid4()
    const timestamp = new Date().toISOString()
    // Note: Math.max(...) is Infinity if the length of goals is 0.
    const priority = state.goals.length
      ? Math.max(...state.goals.map(goal => goal.priority)) + 10
      : 10
    const goal: Goal = {
      priority,
      ...data,
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    commit('createGoal', goal)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.FOCUS,
        model: Models.GOAL,
        action_type: ActionTypes.CREATE,
        data: goal,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ goalId: data.id, actionId: action.id })
    }
    else {
      return $http
        .$post('goals/', { body: goal })
        .catch((error: any) => console.error('createGoal', error))
    }
  },
  updateGoal({ commit, dispatch, rootState }: ActionContext<FocusState, RootState>, data: Pick<Goal, 'id'> & Partial<Goal>): Promise<{ actionId: ActionId } | void> {
    const goal = {
      ...data,
      updated_dts: new Date(),
      synced: false,
    }
    commit('updateGoal', goal)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.FOCUS,
        model: Models.GOAL,
        action_type: ActionTypes.UPDATE,
        data: goal,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http.$patch(`goals/${goal.id}/`, { body: goal })
        .catch((error: any) => console.error('updateGoal', error))
    }
  },
  moveGoal(
    { commit, dispatch, rootState, state }: ActionContext<FocusState, RootState>,
    data: { goalId: GoalId, oldIdx: number, newIdx: number },
  ): Promise<{ actionId: ActionId } | void> {
    const goalId = data.goalId
    const newGoalList = [...state.goals]
    const newIdx = data.newIdx
    moveElement(newGoalList, data.oldIdx, newIdx)
    // Calculate new bullet position and update `Goal`.
    let newPosVal
    if (newIdx === 0) {
      // First position.
      newPosVal = newGoalList[1]!.priority + 10
    }
    else if (newIdx < newGoalList.length - 1) {
      // Not last position.
      newPosVal = (newGoalList[newIdx + 1]!.priority + newGoalList[newIdx - 1]!.priority) / 2
    }
    else {
      // Last position.
      newPosVal = newGoalList[newGoalList.length - 2]!.priority - 10
    }
    const goal = {
      id: goalId,
      priority: newPosVal,
      synced: false,
      updated_dts: new Date(),
    }
    commit('updateGoal', goal)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.FOCUS,
        model: Models.GOAL,
        action_type: ActionTypes.UPDATE,
        data: goal,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`/goals/${goalId}/`, { body: goal })
        .catch((error: any) => console.error('moveGoal', error))
    }
  },
  deleteGoal({ commit, dispatch, rootState }: ActionContext<FocusState, RootState>, goalId: GoalId): Promise<{ actionId: ActionId } | void> {
    commit('deleteGoal', goalId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.FOCUS,
        model: Models.GOAL,
        action_type: ActionTypes.DELETE,
        data: {
          id: goalId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http.$delete(`goals/${goalId}/`)
        .catch((error: any) => console.error('focus/deleteGoal', error))
    }
  },
}
