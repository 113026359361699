export const ACCOUNT_TYPES: { [key: number]: AccountType } = {
  0: {
    name: 'test',
    label: 'Test',
    info: 'For test purposes only.',
  },
  1: {
    name: 'invite-only',
    label: 'Invite only',
    icon: 'fa6-solid:medal',
    info: 'You have access to all features for free.',
  },
  2: {
    name: 'early-bird',
    label: 'Early Bird',
    icon: 'fa6-solid:crow',
    info: 'Thanks for being part of Journalistic since the beginning.',
  },
  3: {
    name: 'basic',
    label: 'Basic',
    info: 'Upgrade to PRO to get access to Focus, Insights, Beta features, and more and help support the development of Journalistic (coming soon).',
  },
  4: {
    name: 'pro',
    label: 'Pro',
    info: 'Thank you for supporting the development of Journalistic, it would not be possible without you! You get to enjoy Focus, Insights, Beta features, and more on top of the basic features.',
  },
  5: {
    name: 'hall-of-fame',
    label: 'Hall of Fame',
    icon: 'fa6-solid:trophy',
    info: 'Thanks for helping to make Journalistic better. You have access to all features for free.',
  },
}
