import type { PeopleState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: PeopleState): void {
    Object.assign(state, defaultState)
  },
  /**
   * People
   */
  populatePeopleDict(state: PeopleState): void {
    state.peopleDict = listMapper(state.people)
    state.peopleByName = listMapperDynamic(state.people, item => item.name.toLowerCase())
  },
  updatePeople(state: PeopleState, data: Person[]): void {
    state.people = data
    state.peopleDict = listMapper(state.people)
    state.peopleByName = listMapperDynamic(state.people, item => item.name.toLowerCase())
  },
  createPerson(state: PeopleState, data: Person): void {
    const peopleCount = state.people.length
    if (!peopleCount) {
      state.people.push(data)
    }
    else {
      let targetIdx = 0
      while (targetIdx < peopleCount) {
        if (state.people[targetIdx]!.name > data.name) {
          break
        }
        targetIdx++
      }
      state.people.splice(targetIdx, 0, data)
    }
    state.peopleDict[data.id] = data
    state.peopleByName[data.name.toLowerCase()] = data
  },
  updatePerson(state: PeopleState, data: Pick<Person, 'id'> & Partial<Person>): void {
    const objId = data.id
    const idx = state.people.findIndex(person => person.id === objId)
    const origObj = structuredClone(toRaw(state.peopleDict[objId])) as Person
    const newObj = Object.assign({}, origObj, data)
    state.people[idx] = newObj
    state.peopleDict[objId] = newObj
    state.peopleByName[origObj.name.toLowerCase()] = newObj
  },
  deletePerson(state: PeopleState, personId: PersonId): void {
    const personName = state.peopleDict[personId]?.name.toLowerCase()
    const idx = state.people.findIndex(obj => obj.id === personId)
    if (idx !== -1)
      state.people.splice(idx, 1)
    if (state.peopleDict[personId])
      delete state.peopleDict[personId]
    if (personName && state.peopleByName[personName])
      delete state.peopleByName[personName]
  },
  addBulletToPerson(state: PeopleState, data: { personId: PersonId, bulletId: BulletId, index?: number }): void {
    const person = state.peopleDict[data.personId]
    if (person) {
      person.bullets.splice(data.index || 1e6, 0, data.bulletId)
    }
  },
  removeBulletFromPerson(state: PeopleState, data: { personId: PersonId, bulletId: BulletId }): void {
    const person = state.peopleDict[data.personId]
    person?.bullets.splice(person.bullets.indexOf(data.bulletId), 1)
  },
  addGroupToPerson(state: PeopleState, data: { personId: PersonId, personGroupId: PersonGroupId }): void {
    state.peopleDict[data.personId]?.groups.push(data.personGroupId)
  },
  removeGroupFromPerson(state: PeopleState, data: { personId: PersonId, personGroupId: PersonGroupId }): void {
    const personGroupId = data.personGroupId
    const idx = state.peopleDict[data.personId]?.groups.findIndex(id => id === personGroupId)
    if (idx !== undefined)
      state.peopleDict[data.personId]?.groups.splice(idx, 1)
  },
  /**
   * PersonGroups
   */
  populatePersonGroupsDict(state: PeopleState): void {
    state.personGroupsDict = listMapper(state.personGroups)
  },
  updatePersonGroups(state: PeopleState, data: PersonGroup[]): void {
    state.personGroups = data
    state.personGroupsDict = listMapper(state.personGroups)
  },
  createPersonGroup(state: PeopleState, data: PersonGroup): void {
    let targetIdx = 0
    while (targetIdx < state.personGroups.length) {
      if (state.personGroups[targetIdx]!.name > data.name) {
        break
      }
      targetIdx++
    }
    state.personGroups.splice(targetIdx, 0, data)
    state.personGroupsDict[data.id] = data
  },
  updatePersonGroup(state: PeopleState, data: Pick<PersonGroup, 'id'> & Partial<PersonGroup>): void {
    const objId = data.id
    const idx = state.personGroups.findIndex(personGroup => personGroup.id === objId)
    const newObj = Object.assign({}, state.personGroups[idx], data)
    state.personGroups[idx] = newObj
    state.personGroupsDict[objId] = newObj
  },
  deletePersonGroup(state: PeopleState, id: PersonGroupId): void {
    const idx = state.personGroups.findIndex(obj => obj.id === id)
    if (idx !== -1) {
      state.personGroups.splice(idx, 1)
    }
    if (state.personGroupsDict[id]) {
      delete state.personGroupsDict[id]
    }
  },
  addPersonToGroup(state: PeopleState, data: { personGroupId: PersonGroupId, personId: PersonId }) {
    state.personGroupsDict[data.personGroupId]?.people.push(data.personId)
  },
  removePersonFromGroup(state: PeopleState, data: { personGroupId: PersonGroupId, personId: PersonId }) {
    const personId = data.personId
    const idx = state.personGroupsDict[data.personGroupId]?.people.findIndex(id => id === personId)
    if (idx !== undefined)
      state.personGroupsDict[data.personGroupId]?.people.splice(idx, 1)
  },
}
