import type { DreamState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: DreamState): void {
    Object.assign(state, defaultState)
  },
  populateDreamsDict(state: DreamState): void {
    state.dreamsDict = listMapper(state.dreams)
  },
  loadDreams(state: DreamState, data: Dream[]): void {
    state.dreams = data
    state.dreamsDict = listMapper(state.dreams)
  },
  createDream(state: DreamState, data: Dream): void {
    if (!state.dreamsDict[data.id]) {
      // Find the correct index where to insert the new Dream based on its date.
      const todoDate = data.date
      let targetIdx = 0
      for (const todo of state.dreams) {
        if (todo.date < todoDate) {
          break
        }
        targetIdx++
      }
      state.dreamsDict[data.id] = data
      state.dreams.splice(targetIdx, 0, data)
    }
  },
  updateDream(state: DreamState, data: Pick<Dream, 'id'> & Partial<Dream>): void {
    const objId = data.id
    const idx = state.dreams.findIndex(dream => dream.id === objId)
    const newObj = Object.assign({}, state.dreamsDict[objId], data)
    state.dreams[idx] = newObj
    state.dreamsDict[objId] = newObj
  },
  deleteDream(state: DreamState, id: DreamId): void {
    const idx = state.dreams.findIndex((obj: Dream) => obj.id === id)
    state.dreams.splice(idx, 1)
    delete state.dreamsDict[id]
  },
}
