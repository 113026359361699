export interface PeopleState {
  people: Person[]
  peopleDict: { [id: PersonId]: Person }
  peopleByName: { [name: string]: Person }
  personGroups: PersonGroup[]
  personGroupsDict: { [id: PersonGroupId]: PersonGroup }
}

export const defaultState: PeopleState = ({
  people: [],
  peopleDict: {},
  peopleByName: {}, // Mapping of people names (lower case) to ID.
  personGroups: [],
  personGroupsDict: {},
})

export default () => Object.assign({}, defaultState)
