export interface FocusState {
  goals: Goal[]
  goalsDict: { [id: GoalId]: Goal }
}

export const defaultState: FocusState = ({
  goals: [],
  goalsDict: {},
})

export default () => Object.assign({}, defaultState)
