import type { NoteState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: NoteState): void {
    Object.assign(state, defaultState)
  },
  /**
   * Notes
   */
  populateNotesDict(state: NoteState): void {
    state.notesDict = listMapper(state.notes)
  },
  loadNotes(state: NoteState, data: Note[]): void {
    state.notes = data
    state.notesDict = listMapper(state.notes)
  },
  createNote(state: NoteState, data: Note): void {
    if (!state.notesDict[data.id]) {
      // Find the correct index where to insert the new Note based on its creation date.
      const noteCreatedDts = data.created_dts
      let targetIdx = 0
      for (const note of state.notes) {
        if (note.created_dts < noteCreatedDts) {
          break
        }
        targetIdx++
      }
      state.notesDict[data.id] = data
      state.notes.splice(targetIdx, 0, data)
    }
  },
  updateNote(state: NoteState, data: Pick<Note, 'id'> & Partial<Note>): void {
    const objId = data.id
    const idx = state.notes.findIndex(note => note.id === objId)
    const newObj = Object.assign({}, state.notesDict[objId], data)
    state.notes[idx] = newObj
    state.notesDict[objId] = newObj
  },
  deleteNote(state: NoteState, id: NoteId): void {
    const idx = state.notes.findIndex(obj => obj.id === id)
    state.notes.splice(idx, 1)
    delete state.notesDict[id]
  },
  addLabelToNote(state: NoteState, data: { noteId: NoteId, noteLabelId: NoteLabelId }): void {
    state.notesDict[data.noteId].labels.push(data.noteLabelId)
  },
  removeLabelFromNote(state: NoteState, data: { noteId: NoteId, noteLabelId: NoteLabelId }): void {
    const noteLabelId = data.noteLabelId
    const idx = state.notesDict[data.noteId].labels.findIndex(id => id === noteLabelId)
    state.notesDict[data.noteId].labels.splice(idx, 1)
  },
  /**
   * NoteLabels
   */
  populateNoteLabelsDict(state: NoteState): void {
    state.noteLabelsDict = listMapper(state.noteLabels)
  },
  updateNoteLabels(state: NoteState, data: NoteLabel[]): void {
    state.noteLabels = data
    state.noteLabelsDict = listMapper(state.noteLabels)
  },
  createNoteLabel(state: NoteState, data: NoteLabel): void {
    if (!state.noteLabelsDict[data.id]) {
      // Find the correct index where to insert the new NoteLabel based on its creation date.
      const noteLabelCreatedDts = data.created_dts
      let targetIdx = 0
      for (const noteLabel of state.noteLabels) {
        if (noteLabel.created_dts < noteLabelCreatedDts) {
          break
        }
        targetIdx++
      }
      state.noteLabelsDict[data.id] = data
      state.noteLabels.splice(targetIdx, 0, data)
    }
  },
  updateNoteLabel(state: NoteState, data: Pick<NoteLabel, 'id'> & Partial<NoteLabel>): void {
    const objId = data.id
    const idx = state.noteLabels.findIndex(noteLabel => noteLabel.id === objId)
    const newObj = Object.assign({}, state.noteLabelsDict[objId], data)
    state.noteLabels[idx] = newObj
    state.noteLabelsDict[objId] = newObj
  },
  deleteNoteLabel(state: NoteState, id: NoteLabelId): void {
    const idx = state.noteLabels.findIndex(obj => obj.id === id)
    state.noteLabels.splice(idx, 1)
    delete state.noteLabelsDict[id]
  },
  addNoteToLabel(state: NoteState, data: { noteLabelId: NoteLabelId, noteId: NoteId }): void {
    state.noteLabelsDict[data.noteLabelId].notes.push(data.noteId)
  },
  removeNoteFromLabel(state: NoteState, data: { noteLabelId: NoteLabelId, noteId: NoteId }): void {
    const noteId = data.noteId
    const idx = state.noteLabelsDict[data.noteLabelId].notes.findIndex(id => id === noteId)
    state.noteLabelsDict[data.noteLabelId].notes.splice(idx, 1)
  },
}
