export interface IdeaState {
  ideas: Idea[]
  ideasDict: { [id: IdeaId]: Idea }
}

export const defaultState: IdeaState = ({
  ideas: [],
  ideasDict: {},
})

export default () => Object.assign({}, defaultState)
