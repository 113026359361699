import type { FocusState } from './state'

export default {
  goals(state: FocusState): Goal[] | undefined {
    return state.goals
  },
  goalsDict(state: FocusState): { [id: GoalId | string]: Goal } | undefined {
    return state.goalsDict
  },
}
