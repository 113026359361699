import type { RouterOptions } from '@nuxt/schema'

export default <RouterOptions> {
  scrollBehavior(to, _from, savedPosition) {
    const scrollElementSelector = '#page-content'
    const scrollElement = document.querySelector(scrollElementSelector)
    if (scrollElement) {
      let scrollPosition: ScrollPosition
      if (savedPosition) {
        const savedScrollPositions = useState('savedScrollPositions', (): Record<string, ScrollPosition> => ({}))
        scrollPosition = savedScrollPositions.value[to.fullPath]
      }
      if (!scrollPosition) {
        scrollPosition = { top: 0, left: 0 }
      }
      scrollElement.scrollTop = scrollPosition!.top
    }
  },
}
