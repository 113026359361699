import * as Sentry from '@sentry/vue'
import { version } from '~/package.json'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  const release = `journalistic-pwa@${version}`
  const environment = import.meta.env.MODE

  console.log('ENV', environment)

  if (environment !== 'development') {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: 'https://aa0f4cd5bbe34ca193b219566de402b4@o469931.ingest.sentry.io/5727327',
      release,
      environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
      ],
      tracesSampleRate: 0.05,
    })
  }
})
