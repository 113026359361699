import type { JournalState } from './state'

export default {

  // Entries

  entries(state: JournalState): Entry[] {
    return state.entries
  },
  entriesDict(state: JournalState): { [id: number]: Entry } {
    return state.entriesDict
  },
  entriesCount(state: JournalState): number {
    return state.entries?.length || 0
  },
  firstEntryDate(state: JournalState): DateString | undefined {
    return state.entries?.length ? state.entries[state.entries.length - 1]?.date : undefined
  },
  lastEntryDate(state: JournalState): DateString | undefined {
    return state.entries?.length ? state.entries[0]?.date : undefined
  },

  // Bullets

  bullets(state: JournalState): Bullet[] {
    return state.bullets
  },
  bulletsDict(state: JournalState): { [id: number]: Bullet } {
    return state.bulletsDict
  },
  bulletsCount(state: JournalState): number {
    return state.bullets?.length || 0
  },
}
