import type { ActionContext } from 'vuex'
import type { TodoState } from './state'
import { addHours, format } from 'date-fns'
import type { RootState } from '~/store/state'

export default {
  resetState({ commit }: ActionContext<TodoState, RootState>): void {
    commit('resetState')
  },
  fetchData({ commit }: ActionContext<TodoState, RootState>): Promise<void> {
    return $http
      .$get('/todos/')
      .then((data: Todo[]) => commit('loadTodos', data))
      .catch((error: any) => console.error('fetchTodos', error))
  },
  createTodo({ commit, state }: ActionContext<TodoState, RootState>, data: Partial<Todo>): { todoId: TodoId, actionId: ActionId } {
    const id = uuid4()
    // Note: Math.min(...) is Infinity if the length of todos is 0.
    const priority = state.todos?.length
      ? Math.min(...state.todos.map((todo: Todo) => todo.priority)) - 10
      : 0
    const todo = Object.assign({}, {
      id,
      priority,
      due_date: format(new Date(), 'yyyy-MM-dd'),
      completed_dts: null,
      synced: false,
    }, data)
    const action = generateAction({
      module: Modules.TODOS,
      model: Models.TODO,
      action_type: ActionTypes.CREATE,
      data: todo,
    })
    commit('createTodo', todo)
    commit('queueAction', action, { root: true })
    return { todoId: id, actionId: action.id }

    // $http.$post(`todos/`, { body: todo })
    //   .catch((error: any) => console.error('createTodo', error))
  },
  updateTodo({ commit }: ActionContext<TodoState, RootState>, data: Pick<Todo, 'id'> & Partial<Todo>): { actionId: ActionId } {
    data.synced = false
    const action = generateAction({
      module: Modules.TODOS,
      model: Models.TODO,
      action_type: ActionTypes.UPDATE,
      data,
    })
    commit('updateTodo', data)
    commit('queueAction', action, { root: true })
    return { actionId: action.id }

    // $http.$patch(`todos/${data.id}/`, { body: data })
    //   .catch((error: any) => console.error('updateTodo', error))
  },
  moveTodo({ commit, getters, state }: ActionContext<TodoState, RootState>, data: { todo: Todo, currentList: TodoListId, newList: TodoListId, oldIdx: number, newIdx: number }): { actionId: ActionId } {
    // Get the new todo list to calculate the new priority.
    const newTodoList: Todo[] = getters[`todosFor${data.newList.charAt(0).toUpperCase() + data.newList.slice(1)}`]

    // Determine the new due_date.
    let newDueDate
    if (data.currentList === data.newList) {
      newDueDate = data.todo.due_date
    }
    else {
      switch (data.newList) {
        case 'today':
          newDueDate = format(new Date(), 'yyyy-MM-dd')
          break
        case 'tomorrow':
          newDueDate = format(addHours(new Date(), 24), 'yyyy-MM-dd')
          break
        default:
          newDueDate = null
      }
    }

    // Move the todo within the full list.
    // Note that it is important to retrieve the index from the full list,
    // oldIdx and newIdx correspond to the index in the filtered lists.

    const oldIdx = state.todos.findIndex((todo: Todo) => todo.id === data.todo.id)

    let refId: TodoId
    let refIdx: number
    let newIdx: number
    let offset = 0

    if (data.currentList === data.newList) {
      // If the item moves within its current list, just swap the indeces.
      const newId = newTodoList[data.newIdx]!.id
      newIdx = state.todos.findIndex((todo: Todo) => todo.id === newId)
    }
    else if (!newTodoList.length) {
      // If the new list has no entries, moving is not necessary.
      newIdx = oldIdx
    }
    else {
      // Account for the case that the new index might not yet exist in the
      // target list, since an additional item is added.
      if (data.newIdx > newTodoList.length - 1) {
        refId = newTodoList[data.newIdx - 1]!.id
        offset += 1
      }
      else {
        refId = newTodoList[data.newIdx]!.id
      }
      refIdx = state.todos.findIndex((todo: Todo) => todo.id === refId)
      newIdx = refIdx + offset

      // Correct for the shift, due to slicing the old index.
      if (newIdx > oldIdx)
        newIdx--
    }

    moveElement(state.todos, oldIdx, newIdx)

    // Calculate new todo position and update Todo.
    // Note that the first item has the highest priority.
    let newPosVal
    if (newIdx === 0) {
      // First position.
      newPosVal = state.todos[0]!.priority + 10
    }
    else if (newIdx < state.todos.length - 1) {
      // Not last position.
      newPosVal = (state.todos[newIdx + 1]!.priority + state.todos[newIdx - 1]!.priority) / 2
    }
    else {
      // Last position.
      newPosVal = state.todos[state.todos.length - 2]!.priority - 10
    }

    const action = generateAction({
      module: Modules.TODOS,
      model: Models.TODO,
      action_type: ActionTypes.UPDATE,
      data: { priority: newPosVal },
    })
    commit('updateTodo', { id: data.todo.id, synced: false, priority: newPosVal, due_date: newDueDate })
    commit('queueAction', action, { root: true })
    return { actionId: action.id }

    // $http
    //   .$patch(`/todos/${data.todo.id}/`, { body: { priority: newPosVal, due_date: newDueDate } })
    //   .catch((error: any) => console.error('moveTodo', error))
  },
  deleteTodo({ commit }: ActionContext<TodoState, RootState>, todoId: TodoId): { actionId: ActionId } {
    const action = generateAction({
      module: Modules.TODOS,
      model: Models.TODO,
      action_type: ActionTypes.DELETE,
      data: {
        id: todoId,
        deleted_dts: new Date().toISOString(),
      },
    })
    commit('deleteTodo', todoId)
    commit('addActionToQueue', action, { root: true })
    return { actionId: action.id }

    // $http.$delete(`todos/${todoId}/`)
    //   .catch((error: any) => console.error('deleteTodo', error))
  },
  clearMissingTodos({ commit, state }: ActionContext<TodoState, RootState>) {
    const idxToClear: number[] = []
    state.todos.forEach((todo: any, idx: number) => {
      if (!todo) {
        idxToClear.push(idx)
      }
    })
    idxToClear.forEach((idx: number) => {
      commit('purgeTodoIndex', idx)
    })
  },
}
