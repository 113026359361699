export interface JournalState {
  entries: Entry[]
  entriesDict: { [id: EntryId]: Entry }
  bullets: Bullet[]
  bulletsDict: { [id: BulletId]: Bullet }
}

export const defaultState: JournalState = ({
  entries: [],
  entriesDict: {},
  bullets: [],
  bulletsDict: {},
})

export default () => Object.assign({}, defaultState)
