import type { TodoState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: TodoState): void {
    Object.assign(state, defaultState)
  },
  populateTodosDict(state: TodoState): void {
    state.todosDict = listMapper(state.todos)
  },
  loadTodos(state: TodoState, data: Todo[]): void {
    data.sort((a, b) => a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0)
    state.todos = data
    state.todosDict = listMapper(state.todos)
  },
  createTodo(state: TodoState, data: Todo): void {
    if (!state.todosDict[data.id]) {
      // Find the correct index where to insert the new Todo based on its priority.
      const todoPriority = data.priority
      let targetIdx = 0
      for (const todo of state.todos) {
        if (todo.priority < todoPriority) {
          break
        }
        targetIdx++
      }
      state.todosDict[data.id] = data
      state.todos.splice(targetIdx, 0, data)
    }
  },
  updateTodo(state: TodoState, data: Pick<Todo, 'id'>): void {
    const objId = data.id
    const idx = state.todos.findIndex(todo => todo.id === objId)
    const origObj = structuredClone(toRaw(state.todos[idx])) as Todo
    // const origPriority = origObj.priority // Store explicitly, otherwise we'll only get an observer.
    const newObj = Object.assign(origObj, data) // Allows partial update of object.

    state.todos[idx] = newObj // $set required to keep bullets list in entry reactive (ordering bug.
    state.todosDict[objId] = newObj

    // If the priority has changed, sort the todos list.
    // if (newObj.priority !== origPriority) {
    //   state.todos.sort((a, b) => a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0)
    // }
  },
  deleteTodo(state: TodoState, todoId: TodoId): void {
    const idx = state.todos.findIndex(todo => todo.id === todoId)
    if (idx !== -1) {
      state.todos.splice(idx, 1)
    }
    if (state.todosDict[todoId]) {
      delete state.todosDict[todoId]
    }
  },
  purgeTodoIndex(state: TodoState, index: number): void {
    state.todos.splice(index, 1)
  },
}
