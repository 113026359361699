import type { TagState } from './state'

export default {
  /**
   * Tags
   */
  tags(state: TagState): Tag[] {
    return state.tags
  },
  tagsDict(state: TagState): Record<TagId, Tag> {
    return state.tagsDict
  },
  tagsByName(state: TagState): Record<string, Tag> {
    return state.tagsByName
  },
  tagsCount(state: TagState): number {
    return state.tags ? state.tags.length : 0
  },
  /**
   * TagGroups
   */
  tagGroups(state: TagState): TagGroup[] {
    return state.tagGroups
  },
  tagGroupsDict(state: TagState): Record<TagGroupId, TagGroup> {
    return state.tagGroupsDict
  },
  tagGroupsCount(state: TagState): number {
    return state.tagGroups ? state.tagGroups.length : 0
  },
}
