export interface TodoState {
  todos: Todo[]
  todosDict: { [id: TodoId]: Todo }
}

export const defaultState: TodoState = ({
  todos: [],
  todosDict: {},
})

export default () => Object.assign({}, defaultState)
