import type { IdeaState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: IdeaState): void {
    Object.assign(state, defaultState)
  },
  populateIdeaDict(state: IdeaState): void {
    state.ideasDict = listMapper(state.ideas)
  },
  loadIdeas(state: IdeaState, data: Idea[]): void {
    state.ideas = data
    state.ideasDict = listMapper(state.ideas)
  },
  createIdea(state: IdeaState, data: Idea): void {
    if (!state.ideasDict[data.id]) {
      // Find the correct index where to insert the new Idea based on its creation date.
      const ideaCreatedDts = data.created_dts
      let targetIdx = 0
      for (const idea of state.ideas) {
        if (idea.created_dts < ideaCreatedDts) {
          break
        }
        targetIdx++
      }
      state.ideasDict[data.id] = data
      state.ideas.splice(targetIdx, 0, data)
    }
  },
  updateIdea(state: IdeaState, data: Pick<Idea, 'id'> & Partial<Idea>): void {
    const objId = data.id
    const idx = state.ideas.findIndex((idea: Idea) => idea.id === objId)
    const newObj = Object.assign({}, state.ideasDict[objId], data)
    state.ideas[idx] = newObj
    state.ideasDict[objId] = newObj
  },
  deleteIdea(state: IdeaState, id: IdeaId): void {
    const idx = state.ideas.findIndex((obj: Idea) => obj.id === id)
    state.ideas.splice(idx, 1)
    delete state.ideasDict[id]
  },
}
