import type {
  Device, DeviceSettings, Profile, ProfileSettings, RootState,
  RootStateKey,
  Sync, UI, UIx, Version,
} from './state'
import {
  defaultDeviceSettings, defaultDeviceState, defaultProfileSettings,
  getDefaultDeviceSettings, getDefaultState,
} from './state'

export default {
  updateState(state: RootState, data: Partial<RootState>): void {
    Object.keys(data).forEach((key) => {
      state[key as RootStateKey] = data[key as RootStateKey] as any
    })
  },
  populateProfile(state: RootState, data: Profile): void {
    state.profile = data
  },
  populateActionsDict(state: RootState): void {
    state.sync.actionsDict = listMapper(state.sync.actionQueue)
  },
  resetDeviceState(state: RootState): void {
    Object.assign(state.device, defaultDeviceState)
  },
  updateProfile(state: RootState, data: Partial<Profile>): void {
    if (state.profile) {
      Object.keys(data).forEach((key) => {
        (state.profile as any)[key] = data[key as keyof Profile]
      })
    }
  },
  resetProfile(state: RootState): void {
    state.profile = undefined
  },
  populateProfileSettings(state: RootState, data: Partial<ProfileSettings>): void {
    state.profileSettings = Object.assign({}, defaultProfileSettings, data)
  },
  updateProfileSettings(state: RootState, data: Partial<ProfileSettings>): void {
    if (state.profileSettings) {
      Object.keys(data).forEach((key) => {
        (state.profileSettings as any)[key] = data[key as keyof ProfileSettings]
      })
    }
  },
  updateDevice(state: RootState, data: Partial<Device>): void {
    console.time('[M] updateDevice')
    Object.keys(data).forEach((key) => {
      (state.device as any)[key] = data[key as keyof Device]
    })
    console.timeEnd('[M] updateDevice')
  },
  populateDeviceSettings(state: RootState, data: Partial<DeviceSettings>): void {
    state.deviceSettings = Object.assign({}, defaultDeviceSettings, state.deviceSettings || {}, data)
  },
  updateDeviceSettings(state: RootState, data: Partial<DeviceSettings>): void {
    if (state.deviceSettings) {
      Object.keys(data).forEach((key) => {
        (state.deviceSettings as any)[key] = data[key as keyof DeviceSettings]
      })
    }
  },
  updateUI(state: RootState, data: Partial<UI>): void {
    console.time('[M] updateUI')
    Object.keys(data).forEach((key) => {
      (state.ui as any)[key] = data[key as keyof UI]
    })
    console.timeEnd('[M] updateUI')
  },
  updateUIx(state: RootState, data: Partial<UIx>): void {
    console.time('[M] updateUIx')
    Object.keys(data).forEach((key) => {
      (state.uix as any)[key] = data[key as keyof UIx]
    })
    console.timeEnd('[M] updateUIx')
  },
  setSync(state: RootState, data: Sync): void {
    state.sync = data
  },
  updateSync(state: RootState, data: Partial<Sync>): void {
    Object.keys(data).forEach((key) => {
      (state.sync as any)[key] = data[key as keyof Sync]
    })
  },
  setVersion(state: RootState, data: Version): void {
    state.version = data
  },
  updateVersion(state: RootState, data: Partial<Version>): void {
    Object.keys(data).forEach((key) => {
      (state.version as any)[key] = data[key as keyof Version]
    })
  },
  clearRandomGem(state: RootState): void {
    state.ui.dailyRandomGem.gemId = undefined
  },
  clearRandomIdea(state: RootState): void {
    state.ui.dailyRandomIdea.ideaId = undefined
  },
  resetState(state: RootState): void {
    // Merge rather than replace so we don't lose observers:
    // https://github.com/vuejs/vuex/issues/1118
    // BE CAREFUL, this also erases persisted state vars.
    Object.assign(state, getDefaultState())
  },
  resetSettings(state: RootState): void {
    if (state.deviceSettings) {
      Object.assign(state.deviceSettings, defaultDeviceSettings)
    }
    else {
      state.deviceSettings = getDefaultDeviceSettings()
    }
  },
  // Action Queue
  queueAction(state: RootState, action: Action): void {
    state.sync.actionQueue.push(action)
    state.sync.actionsDict[action.id] = action
  },
  updateAction(state: RootState, data: Pick<Action, 'id'> & Partial<Action>): void {
    const objId = data.id
    const idx = state.sync.actionQueue.findIndex(action => action.id === objId)
    const newObj = Object.assign({}, state.sync.actionsDict[data.id], data)
    state.sync.actionQueue[idx] = newObj
    state.sync.actionsDict[data.id] = newObj
  },
  deleteAction(state: RootState, actionId: ActionId): void {
    const idx = state.sync.actionQueue.findIndex(action => action.id === actionId)
    state.sync.actionQueue.splice(idx, 1)
    delete state.sync.actionsDict[actionId]
  },
  clearSyncQueue(state: RootState): void {
    state.sync['actionQueue'] = []
    state.sync['actionsDict'] = {}
  },
}
