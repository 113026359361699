import type { ActionContext } from 'vuex'
import type { RootState } from '../state'
import type { DreamState } from './state'

export default {
  resetState({ commit }: ActionContext<DreamState, RootState>): void {
    commit('resetState')
  },
  fetchData({ commit }: ActionContext<DreamState, RootState>): Promise<void> {
    return $http
      .$get('/journal/dreams/')
      .then((data: Dream[]) => commit('loadDreams', data))
      .catch((error: any) => console.error('fetchDreams', error))
  },
  createDream(
    { commit, dispatch, rootState }: ActionContext<DreamState, RootState>,
    data: Omit<Dream, 'id' | 'created_dts' | 'updated_dts'>,
  ): Promise<{ dreamId: DreamId, actionId: ActionId } | void> {
    const id = uuid4()
    const timestamp = new Date().toISOString()
    const entryDate = rootState.journal.entriesDict[data.entry]?.date
    if (!entryDate)
      return Promise.reject(new Error('Entry not found'))
    const wordCount = getWordCount(data.text as string)
    const dream = {
      id,
      ...data,
      date: entryDate,
      word_count: wordCount,
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    commit('createDream', dream)
    commit('journal/addDreamToEntry', { entryId: dream.entry, dreamId: dream.id }, { root: true })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.DREAMS,
        model: Models.DREAM,
        action_type: ActionTypes.CREATE,
        data: dream,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ dreamId: id, actionId: action.id })
    }
    else {
      return $http
        .$post('journal/dreams/', { body: dream })
        .catch((error: any) => console.error('createDream', error))
    }
  },
  updateDream({ commit, dispatch, rootState }: ActionContext<DreamState, RootState>, data: Pick<Dream, 'id'> & Partial<Omit<Dream, 'created_dts' | 'updated_dts'>>): Promise<{ actionId: ActionId } | void> {
    const dream = {
      ...data,
      updated_dts: new Date(),
      synced: false,
    }
    if (dream.text !== undefined) {
      dream.word_count = getWordCount(data.text as string)
    }
    commit('updateDream', dream)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.DREAMS,
        model: Models.DREAM,
        action_type: ActionTypes.UPDATE,
        data: dream,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`journal/dreams/${dream.id}/`, { body: dream })
        .catch((error: any) => console.error('updateDream', error))
    }
  },
  deleteDream({ commit, dispatch, rootState, state }: ActionContext<DreamState, RootState>, dreamId: DreamId): Promise<{ actionId: ActionId } | void> {
    const dream = state.dreamsDict[dreamId]
    if (!dream)
      return Promise.resolve()
    const entry = rootState.journal.entriesDict[dream.entry]
    if (!entry)
      return Promise.resolve()
    const entryId = dream.entry
    // Delete entry if the bullet is the last one and if there are no dreams.
    const deleteEntry = entry.dreams.length === 1 && entry.dreams[0] === dreamId && entry.bullets.length === 0
    commit('journal/removeDreamFromEntry', { entryId: dream.entry, dreamId }, { root: true })
    commit('deleteDream', dreamId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.DREAMS,
        model: Models.DREAM,
        action_type: ActionTypes.DELETE,
        data: {
          id: dreamId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`journal/dreams/${dreamId}/`)
        .then(() => {
          if (deleteEntry) {
            dispatch('journal/deleteEntry', entryId, { root: true })
          }
        })
        .catch((error: any) => console.error('deleteDream', error))
    }
  },
}
