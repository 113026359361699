export interface TagState {
  tags: Tag[]
  tagsDict: { [id: TagId]: Tag }
  tagsByName: { [name: string]: Tag }
  tagGroups: TagGroup[]
  tagGroupsDict: { [id: TagGroupId]: TagGroup }
}

export const defaultState: TagState = ({
  tags: [],
  tagsDict: {},
  tagsByName: {}, // Mapping of tag names (lower case) to ID.
  tagGroups: [],
  tagGroupsDict: {},
})

export default () => Object.assign({}, defaultState)
