import type { ActionContext } from 'vuex'
import type { RootState } from '../state'
import type { TagState } from './state'

export default {
  resetState({ commit }: ActionContext<TagState, RootState>): void {
    commit('resetState')
  },
  fetchData({ dispatch }: ActionContext<TagState, RootState>): Promise<[void, void]> {
    return Promise.all([
      dispatch('fetchTags'),
      dispatch('fetchTagGroups'),
    ])
  },
  /**
   * Tags
   */
  fetchTags({ commit }: ActionContext<TagState, RootState>): Promise<void> {
    return $http
      .$get('/tags/')
      .then((tags: Tag[]) => commit('updateTags', tags))
      .catch((error: any) => console.error('fetchTags', error))
  },
  // createTag({ commit }: ActionContext<TagState, RootState>, data: Omit<Tag, 'id' | 'created_dts' | 'updated_dts'>): Promise<Tag> {
  //   // Note: Only used with action sync. Therefore, no distinction required.
  //   const id = uuid4()
  //   const timestamp = new Date().toISOString()
  //   data.bullet_count = data.bullets.length
  //   data.first_mention_date =
  //   data.last_mention_date =
  //   const tag = {
  //     id,
  //     ...data,
  //     created_dts: timestamp,
  //     updated_dts: timestamp,
  //     synced: false,
  //   }
  //   commit('createTag', tag)
  //   const action = generateAction({
  //     module: Modules.IDEAS,
  //     model: Models.IDEA,
  //     action_type: ActionTypes.CREATE,
  //     data: idea,
  //   })
  //   commit('queueAction', action, { root: true })
  //   dispatch('pushActions', {}, { root: true })
  //   return Promise.resolve({ ideaId: id, actionId: action.id })
  // },
  updateTag(
    { commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    data: Pick<Tag, 'id'> & Partial<Tag>,
  ): Promise<{ actionId: ActionId } | void> {
    const tag = Object.assign(
      {},
      toRaw(data),
      {
        updated_dts: new Date().toISOString(),
        synced: false,
      },
    )
    commit('updateTag', tag)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.TAGS,
        model: Models.TAG,
        action_type: ActionTypes.UPDATE,
        data: tag,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`tags/${tag.id}/`, { body: tag })
        .catch((error: any) => console.error('updateTag', error))
    }
  },
  async deleteTag(
    { commit, dispatch, state, rootState }: ActionContext<TagState, RootState>,
    tagId: TagId,
  ): Promise<void> {
    const tag = state.tagsDict[tagId]
    if (!tag)
      return
    const actions: Promise<any>[] = []
    // Remove root if the tag has one.
    if (tag.root) {
      const root = state.tagsDict[tag.root]
      await dispatch('removeAliasFromTag', {
        tag: root,
        aliasId: tag.id,
      })
    }
    // Remove aliases if the tag has any.
    tag.aliases.forEach((aliasId) => {
      actions.push(
        dispatch('removeAliasFromTag', {
          tag,
          aliasId,
        }),
      )
    })
    await Promise.all(actions)
    actions.splice(0)
    // Remove tag from groups if there are any.
    tag.groups.forEach((groupId) => {
      actions.push(
        dispatch('removeTagFromGroup', {
          tagGroupId: groupId,
          tagId: tag.id,
        }),
      )
    })
    await Promise.all(actions)
    actions.splice(0)
    tag.bullets?.forEach((bulletId) => {
      const bullet = rootState.journal.bulletsDict[bulletId]
      if (!bullet)
        return
      const re = new RegExp(`${reWordBoundary}#(${tag.name})${reWordBoundary}`, 'giu')
      const newText = bullet.text.replace(re, (_, boundBf, tagName, boundAf) => {
        return `${boundBf}${tagName}${boundAf}`
      })
      // Remove tag from bullet.
      actions.push(
        dispatch('journal/updateBullet', { id: bullet.id, text: newText }, { root: true }),
      )
    })
    await Promise.all(actions)
    commit('deleteTag', tag.id)
    if (!rootState.profileSettings?.action_sync_enabled) {
      dispatch('fetchTags')
    }
  },
  addAliasToTag(
    { commit, dispatch }: ActionContext<TagState, RootState>,
    data: { tag: Tag, aliasId: TagId },
  ): Promise<{ actionId: ActionId } | void> {
    // Add alias to tag
    commit('updateTag', {
      id: data.tag.id,
      aliases: [...data.tag.aliases, data.aliasId],
    })
    dispatch('recompTagProps', data.tag.id)
    return dispatch('updateTag', { id: data.aliasId, root: data.tag.id })
  },
  removeAliasFromTag(
    { commit, dispatch }: ActionContext<TagState, RootState>,
    data: { tag: Tag, aliasId: TagId },
  ): Promise<{ actionId: ActionId } | void> {
    // Remove alias from tag
    commit('updateTag', {
      id: data.tag.id,
      aliases: data.tag.aliases.filter(aliasId => aliasId !== data.aliasId),
    })
    dispatch('recompTagProps', data.tag.id)
    return dispatch('updateTag', { id: data.aliasId, root: null })
  },
  /**
   * Refresh a list of Tag IDs from the backend,
   * aka. create, update or delete them in/from the store.
   * @param {int[]} payload - Tag IDs that need to be refreshed.
   */
  refreshTags(
    { state, commit }: ActionContext<TagState, RootState>,
    tagIds: TagId[],
  ): Promise<void> {
    if (tagIds.length) {
      return $http
        .$get(`tags/?ids=${tagIds.join(',')}`)
        .then((tags: Tag[]) => {
          tags.forEach((tag) => {
            commit(state.tagsDict[tag.id] ? 'updateTag' : 'createTag', tag)
          })
          // IDs that didn't return an object were deleted by the backend, remove them.
          const updatedIds = tags.map(obj => obj.id)
          const deletedIds = tagIds.filter(tagId => !updatedIds.includes(tagId))
          deletedIds.forEach(tagId => commit('deleteTag', tagId))
        })
        .catch((error: any) => console.error('refreshTags', error))
    }
    return Promise.resolve()
  },
  recompTagProps(
    { state, commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    tagId: TagId,
  ): void {
    // Compute tag properties.
    const tag = state.tagsDict[tagId]
    if (!tag)
      return
    const bulletIds = tag.bullets.concat(
      ...tag.aliases.map(aliasId => state.tagsDict[aliasId]?.bullets).filter(Boolean),
    )
    const bullets = bulletIds.map(bulletId => rootState.journal.bulletsDict[bulletId])
    sortByField(bullets, 'date')
    commit('updateTag', {
      id: tagId,
      first_mention_date: bullets.length ? (bullets[0]?.date || null) : null,
      latest_mention_date: bullets.length ? (bullets[bullets.length - 1]?.date || null) : null,
      bullet_count: bullets.length,
    })
    // Update the tag root props as well if the tag has a root.
    if (tag.root) {
      dispatch('recompTagProps', tag.root)
    }
    // Update the props of the tag's groups if it has any.
    tag.groups.forEach(tagGroupId => dispatch('recompTagGroupProps', tagGroupId))
  },
  /**
   * TagGroups
   */
  fetchTagGroups({ commit }: ActionContext<TagState, RootState>): Promise<void> {
    return $http
      .$get('/tags/groups/')
      .then((tagGroups: TagGroup[]) => commit('updateTagGroups', tagGroups))
      .catch((error: any) => console.error('fetchTagGroups', error))
  },
  createTagGroup(
    { commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    data: Omit<TagGroup, 'id' | 'created_dts' | 'updated_dts'>,
  ): Promise<{ tagGroupId: TagGroupId, actionId: ActionId } | void> {
    const id = uuid4()
    const timestamp = new Date().toISOString()
    const tagGroup = {
      id,
      ...data,
      tags: data.tags || [],
      created_dts: timestamp,
      updated_dts: timestamp,
      synced: false,
    }
    commit('createTagGroup', tagGroup)
    tagGroup.tags?.forEach((tagId) => {
      commit('addGroupToTag', { tagId, tagGroupId: tagGroup.id })
    })
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.TAGS,
        model: Models.TAG_GROUP,
        action_type: ActionTypes.CREATE,
        data: tagGroup,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ tagGroupId: id, actionId: action.id })
    }
    else {
      return $http
        .$post('tags/groups/', { body: tagGroup })
        .catch((error: any) => console.error('createTagGroup', error))
    }
  },
  updateTagGroup(
    { commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    data: Pick<TagGroup, 'id'> & Partial<Omit<TagGroup, 'tags' | 'created_dts' | 'updated_dts'>>,
  ): Promise<{ actionId: ActionId } | void> {
    const tagGroup = {
      ...data,
      updated_dts: new Date().toISOString(),
      synced: false,
    }
    commit('updateTagGroup', tagGroup)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.TAGS,
        model: Models.TAG_GROUP,
        action_type: ActionTypes.UPDATE,
        data: tagGroup,
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$patch(`tags/groups/${tagGroup.id}/`, { body: tagGroup })
        .catch((error: any) => console.error('updateTagGroup', error))
    }
  },
  deleteTagGroup(
    { state, commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    tagGroupId: TagGroupId,
  ): Promise<{ actionId: ActionId } | void> {
    const tagGroup = state.tagGroupsDict[tagGroupId]
    // Remove Group from its Tags
    tagGroup?.tags.forEach((tagId: TagId) => {
      commit('removeGroupFromTag', { tagId, tagGroupId })
    })
    commit('deleteTagGroup', tagGroupId)
    if (rootState.profileSettings?.action_sync_enabled) {
      const action = generateAction({
        module: Modules.TAGS,
        model: Models.TAG_GROUP,
        action_type: ActionTypes.DELETE,
        data: {
          id: tagGroupId,
          deleted_dts: new Date().toISOString(),
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$delete(`tags/groups/${tagGroupId}/`)
        .catch((error: any) => console.error('deleteTagGroup', error))
    }
  },
  addTagToGroup(
    { commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    data: { tagGroupId: TagGroupId, tagId: TagId },
  ): Promise<{ actionId: ActionId } | void> {
    commit('addTagToGroup', data)
    commit('addGroupToTag', data)
    if (rootState.profileSettings?.action_sync_enabled) {
      dispatch('recompTagGroupProps', data.tagGroupId)
      dispatch('recompTagProps', data.tagId)
      const action = generateAction({
        module: Modules.TAGS,
        model: Models.TAG_GROUP,
        action_type: ActionTypes.ACTION,
        action_route: 'add_tag',
        data: {
          id: data.tagGroupId,
          tag: data.tagId,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$put(`tags/groups/${data.tagGroupId}/add-tag/`, { body: { tag: data.tagId } })
        .then(() => {
          // TODO: refreshing tags is probably not necessary, only group.
          dispatch('refreshTags', [data.tagId])
          dispatch('refreshTagGroups', [data.tagGroupId])
        })
        .catch((error: any) => console.error('addTagToGroup', error))
    }
  },
  removeTagFromGroup(
    { commit, dispatch, rootState }: ActionContext<TagState, RootState>,
    data: { tagId: TagId, tagGroupId: TagGroupId },
  ): Promise<{ actionId: ActionId } | void> {
    commit('removeTagFromGroup', data)
    commit('removeGroupFromTag', data)
    if (rootState.profileSettings?.action_sync_enabled) {
      dispatch('recompTagGroupProps', data.tagGroupId)
      dispatch('recompTagProps', data.tagId)
      const action = generateAction({
        module: Modules.TAGS,
        model: Models.TAG_GROUP,
        action_type: ActionTypes.ACTION,
        action_route: 'remove_tag',
        data: {
          id: data.tagGroupId,
          tag: data.tagId,
        },
      })
      commit('queueAction', action, { root: true })
      dispatch('pushActions', {}, { root: true })
      return Promise.resolve({ actionId: action.id })
    }
    else {
      return $http
        .$put(`tags/groups/${data.tagGroupId}/remove-tag/`, { body: { tag: data.tagId } })
        .then(() => {
          dispatch('refreshTags', [data.tagId])
          dispatch('refreshTagGroups', [data.tagGroupId])
        })
        .catch((error: any) => console.error('removeTagFromGroup', error))
    }
  },
  /**
   * Refresh a list of TagGroup IDs from the backend,
   * aka. create, update or delete them in/from the store.
   * @param {int[]} payload - TagGroup IDs that need to be refreshed.
   */
  refreshTagGroups({ state, commit }: ActionContext<TagState, RootState>, tagGroupIds: TagGroupId[]): Promise<void> {
    if (tagGroupIds.length) {
      return $http
        .$get(`tags/groups/?ids=${tagGroupIds.join(',')}`)
        .then((tagGroups: TagGroup[]) => {
          tagGroups.forEach(tagGroup => commit(state.tagGroupsDict[tagGroup.id] === undefined ? 'createTagGroup' : 'updateTagGroup', tagGroup))
          // IDs that didn't return an object were deleted by the backend, remove them.
          const updatedIds = tagGroups.map(obj => obj.id)
          const deletedIds = tagGroupIds.filter(tagGroupId => !updatedIds.includes(tagGroupId))
          deletedIds.forEach(tagGroupId => commit('deleteTagGroup', tagGroupId))
        })
        .catch((error: any) => console.error('refreshTagGroups', error))
    }
    return Promise.resolve()
  },
  recompTagGroupProps({ state, commit, rootState }: ActionContext<TagState, RootState>, tagGroupId: TagGroupId): void {
    // Compute TagGroup properties.
    const tagGroup = state.tagGroupsDict[tagGroupId]
    if (!tagGroup)
      return
    const tags = tagGroup.tags.map(tagId => state.tagsDict[tagId]).filter(Boolean)
    const bulletIds = ([] as BulletId[]).concat(...tags.map(tag => (
      tag.bullets.concat(...tag.aliases.map(aliasId => state.tagsDict[aliasId]?.bullets).filter(Boolean))
    )))
    const bullets = bulletIds.map(bulletId => rootState.journal.bulletsDict[bulletId])
    sortByField(bullets, 'date')
    commit('updateTagGroup', {
      first_mention_date: bullets.length ? bullets[0]?.date : null,
      latest_mention_date: bullets.length ? bullets[bullets.length - 1]?.date : null,
      bullet_count: bullets.length,
    })
  },
}
