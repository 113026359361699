import type { TagState } from './state'
import { defaultState } from './state'

export default {
  resetState(state: TagState): void {
    Object.assign(state, defaultState)
  },
  /**
   * Tags
   */
  populateTagsDict(state: TagState): void {
    state.tagsDict = listMapper(state.tags)
    state.tagsByName = listMapperDynamic(state.tags, item => item.name.toLowerCase())
  },
  updateTags(state: TagState, data: Tag[]): void {
    state.tags = data
    state.tagsDict = listMapper(state.tags)
    state.tagsByName = listMapperDynamic(state.tags, item => item.name.toLowerCase())
  },
  createTag(state: TagState, data: Tag): void {
    const tagCount = state.tags.length
    if (!tagCount)
      state.tags.push(data)
    else {
      let targetIdx = 0
      while (targetIdx < tagCount) {
        if (state.tags[targetIdx]!.name > data.name)
          break
        targetIdx++
      }
      state.tags.splice(targetIdx, 0, data)
    }
    state.tagsDict[data.id] = data
    state.tagsByName[data.name.toLowerCase()] = data
  },
  updateTag(state: TagState, data: Pick<Tag, 'id'> & Partial<Tag>): void {
    const objId = data.id
    const idx = state.tags.findIndex(tag => tag.id === objId)
    const origObj = structuredClone(toRaw(state.tagsDict[objId])) as Tag
    const newObj = Object.assign({}, origObj, data)
    state.tags[idx] = newObj
    state.tagsDict[objId] = newObj
    state.tagsByName[origObj.name.toLowerCase()] = newObj
  },
  deleteTag(state: TagState, tagId: TagId): void {
    const tagName = state.tagsDict[tagId]?.name.toLowerCase()
    const idx = state.tags.findIndex(obj => obj.id === tagId)
    if (idx !== -1)
      state.tags.splice(idx, 1)
    if (state.tagsDict[tagId])
      delete state.tagsDict[tagId]
    if (tagName && state.tagsByName[tagName])
      delete state.tagsByName[tagName]
  },
  addBulletToTag(state: TagState, data: { tagId: TagId, bulletId: BulletId, index?: number }): void {
    const tag = state.tagsDict[data.tagId]
    if (tag)
      tag.bullets.splice(data.index || 1e6, 0, data.bulletId)
  },
  removeBulletFromTag(state: TagState, data: { tagId: TagId, bulletId: BulletId }): void {
    const tag = state.tagsDict[data.tagId]
    tag?.bullets.splice(tag.bullets.indexOf(data.bulletId), 1)
  },
  addGroupToTag(state: TagState, data: { tagId: TagId, tagGroupId: TagGroupId }): void {
    state.tagsDict[data.tagId]?.groups.push(data.tagGroupId)
  },
  removeGroupFromTag(state: TagState, data: { tagId: TagId, tagGroupId: TagGroupId }): void {
    const tagGroupId = data.tagGroupId
    const idx = state.tagsDict[data.tagId]?.groups.findIndex(id => id === tagGroupId)
    if (idx !== undefined)
      state.tagsDict[data.tagId]?.groups.splice(idx, 1)
  },
  /**
   * TagGroups
   */
  populateTagGroupsDict(state: TagState): void {
    state.tagGroupsDict = listMapper(state.tagGroups)
  },
  updateTagGroups(state: TagState, data: TagGroup[]): void {
    state.tagGroups = data
    state.tagGroupsDict = listMapper(state.tagGroups)
  },
  createTagGroup(state: TagState, data: TagGroup): void {
    let targetIdx = 0
    while (targetIdx < state.tagGroups.length) {
      if (state.tagGroups[targetIdx]!.name > data.name) {
        break
      }
      targetIdx++
    }
    state.tagGroups.splice(targetIdx, 0, data)
    state.tagGroupsDict[data.id] = data
  },
  updateTagGroup(state: TagState, data: Pick<TagGroup, 'id'> & Partial<TagGroup>): void {
    const objId = data.id
    const idx = state.tagGroups.findIndex(tagGroup => tagGroup.id === objId)
    const newObj = Object.assign({}, state.tagGroupsDict[objId], data)
    state.tagGroups[idx] = newObj
    state.tagGroupsDict[objId] = newObj
  },
  deleteTagGroup(state: TagState, id: TagGroupId): void {
    const idx = state.tagGroups.findIndex(obj => obj.id === id)
    if (idx !== -1)
      state.tagGroups.splice(idx, 1)
    if (state.tagGroupsDict[id])
      delete state.tagGroupsDict[id]
  },
  addTagToGroup(state: TagState, data: { tagGroupId: TagGroupId, tagId: TagId }): void {
    state.tagGroupsDict[data.tagGroupId]?.tags.push(data.tagId)
  },
  removeTagFromGroup(state: TagState, data: { tagGroupId: TagGroupId, tagId: TagId }): void {
    const tagId = data.tagId
    const idx = state.tagGroupsDict[data.tagGroupId]?.tags.findIndex(id => id === tagId)
    if (idx !== undefined)
      state.tagGroupsDict[data.tagGroupId]?.tags.splice(idx, 1)
  },
}
