<template>
  <div class="loading-screen">
    <Icon name="loading" class="animate-spin" />
  </div>
</template>

<style lang="scss" scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
