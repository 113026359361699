import type { PeopleState } from './state'

export default {
  /**
   * People
   */
  people(state: PeopleState): Person[] {
    return state.people
  },
  peopleDict(state: PeopleState): Record<PersonId, Person> {
    return state.peopleDict
  },
  peopleByName(state: PeopleState): Record<string, Person> {
    return state.peopleByName
  },
  peopleCount(state: PeopleState): number {
    return state.people ? state.people.length : 0
  },
  /**
   * PersonGroup
   */
  personGroups(state: PeopleState): PersonGroup[] {
    return state.personGroups
  },
  personGroupsDict(state: PeopleState): Record<PersonGroupId, PersonGroup> {
    return state.personGroupsDict
  },
  personGroupsCount(state: PeopleState): number {
    return state.personGroups ? state.personGroups.length : 0
  },
}
